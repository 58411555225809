import React from 'react';
import { canvasStyle } from './aligner-layout-style';
interface Props { children?: Array<JSX.Element> | JSX.Element; }
const CanvasLayout: React.FC<Props> = ({ children }): JSX.Element => {
  return (
    <div
      style={canvasStyle}
    >{children}</div>
  )
}
export default CanvasLayout;