import { Navigate, Outlet } from "react-router-dom";

import _isEmpty from 'lodash/isEmpty';
import { getSessionCredentialsInStorage } from "../redux/action/auth-action";
import { useAppDispatch } from "../redux/hooks";
import { setSession } from "../redux/slice/auth-slice";
import { SessionCredential } from "../model/auth-model";

const ProtectedRoute: React.FC<any> = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const session: SessionCredential = dispatch(getSessionCredentialsInStorage());
  const isAuthenticated = !_isEmpty(session);
  if (isAuthenticated) {
    dispatch(setSession(session))
  }
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;