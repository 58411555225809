
const pageHeaderContainerStyle: React.CSSProperties = {
  marginTop: "16px",
  marginBottom: "32px",
  alignItems: "center",
  justifyContent: "space-between",
  height: "40px"
};
const pageHeaderTitleStyle: React.CSSProperties = {
  fontSize: "32px",
  fontWeight: "700"
};
const pageHeaderSaveButtonStyle: React.CSSProperties = {
  backgroundColor: "#015B93",
  // height: "40px"
};
const pageHeaderSaveButtonLblStyle: React.CSSProperties = {
  fontSize: "16",
  fontWeight: "400",
  color: "#FFFFFF"
};

export {
  pageHeaderContainerStyle,
  pageHeaderTitleStyle,
  pageHeaderSaveButtonStyle,
  pageHeaderSaveButtonLblStyle
}
