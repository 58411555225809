import './clients-page.css';
import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Form, Modal, Space, Table, Tag, notification } from 'antd';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { AiOutlineCalendar, AiOutlineDelete, AiOutlineEdit, AiOutlineFilePdf } from 'react-icons/ai';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { PiTreeStructureLight } from "react-icons/pi";
import { addControlStyle } from './clients-page-style';
import { useAddClientMutation, useDeleteClientMutation, useGetClientsMutation, useScheduleClientMutation, useUnscheduleClientMutation, useUpdateClientMutation } from '../../redux/api/clients';
import { Client, RESTChannel, SFTPChannel, SOAPChannel } from '../../model/client-model';
import { ClientForm } from './client-form';
import { useNavigate } from 'react-router-dom';

import _isUndefined from 'lodash/isUndefined';

const ClientsPage = () => {

  const updateMenu = {
    key: 'update',
    icon: <AiOutlineEdit />,
    label: 'Update Client'
  };

  const deleteMenu = {
    key: 'delete',
    icon: <AiOutlineDelete />,
    label: 'Delete Client'
  };

  const dataMappingMenu = {
    key: 'datamapping',
    icon: <PiTreeStructureLight />,
    label: 'Open Data Mapping'
  };

  const pdfBuilderMenu = {
    key: 'pdfbuilder',
    icon: <AiOutlineFilePdf />,
    label: 'Open PDF Builder'
  };

  const scheduleMenu = {
    key: 'schedule',
    icon: <AiOutlineCalendar />,
    label: 'Schedule Transmission'
  };

  const unscheduleMenu = {
    key: 'unschedule',
    icon: <AiOutlineCalendar />,
    label: 'Cancel Transmission'
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // sorter: (a: string, b: string) => a.localeCompare(b)
    },
    {
      title: 'Cron Schedule',
      dataIndex: 'cronSchedule',
      key: 'cronSchedule',
      width: 160,
    },
    {
      title: 'Payload',
      dataIndex: 'payloadType',
      key: 'payloadType',
      render: (payloadType: string) => payloadType ? payloadType.replace('_', ' ') : '',
      width: 120,
    },
    {
      title: 'Channel',
      dataIndex: 'channelType',
      key: 'channelType',
      render: (channelType: string) => channelType ? channelType.replace('_', ' ') : '',
      width: 120,
    },
    {
      title: 'Status',
      dataIndex: 'scheduled',
      key: 'scheduled',
      width: 120,
      render: (scheduled: boolean) => {
        return scheduled ? <Tag color="green">Scheduled</Tag> : <Tag color="red">Unscheduled</Tag>
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 96,
      render: (_: any, record: Client) => {
        return <Dropdown
          menu={{
            items: getMenuItems(record.scheduled, record.payloadType),
            onClick: ({ key }) => handleClientAction(key, record)
          }}>
          <div style={{ cursor: 'pointer' }} onClick={(e) => e.preventDefault()}>
            <Space>
              <span className="dropdown-title">
                <BsThreeDotsVertical />
              </span>
            </Space>
          </div>
        </Dropdown>
      }
    },
  ];

  const [isAddOpen, setAddOpen] = useState<boolean>(false);
  const [isEditOpen, setEditOpen] = useState<boolean>(false);
  // const [isDataMappingOpen, setDataMappingOpen] = useState<boolean>(false);
  const [isScheduleOpen, setScheduleOpen] = useState<boolean>(false);
  const [isUnscheduleOpen, setUnscheduleOpen] = useState<boolean>(false);
  const [clients, setClients] = useState<Client[]>([]);
  const [wizardState, setWizardState] = useState<number>(0);

  const [client, setClient] = useState<Client>();

  const [modal, contextHolder] = Modal.useModal();

  const [form] = Form.useForm();

  const [getClients] = useGetClientsMutation();
  const [postClient] = useAddClientMutation();
  const [putClient] = useUpdateClientMutation();
  const [removeClient] = useDeleteClientMutation();
  
  const [postScheduleClient] = useScheduleClientMutation();
  const [postUnscheduleClient] = useUnscheduleClientMutation();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchClients = async () => {
      const clients = await getClients({}).unwrap();
      const indexedClients = clients.map((client: Client, index: number) => {
        return { ...client, key: index };
      });
      setClients(indexedClients);
    }
    fetchClients();
  }, [getClients]);

  /**
   * Gets the menu items available to the client depending on its current state
   * 
   * @param scheduled if scheduled
   * @param payloadType the payload type (XML, JSON, PDF, etc)
   * @returns the menu items
   */
  const getMenuItems = (scheduled: boolean | undefined, payloadType: string | undefined ) => {
    return [
      updateMenu,
      deleteMenu,
      payloadType === 'PDF' ? pdfBuilderMenu : dataMappingMenu,
      !scheduled ? scheduleMenu : unscheduleMenu
    ];
  }

  /**
   * Opens the Add Client Modal.
   */
  const openAddModal = (): void => {
    form.resetFields();
    setWizardState(0);
    setAddOpen(true);
  }

  const nextWizardState = async () => {

    // const valid = await form.validateFields();
    switch (wizardState) {
      case 0: setWizardState(wizardState + 1); break;
      case 1: setWizardState(wizardState + 1); break;
      case 2: form.submit(); break;
    }
  }

  const backWizardState = () => {

    switch (wizardState) {
      case 2: setWizardState(wizardState - 1); break;
      case 1: setWizardState(wizardState - 1); break;
      case 0: setAddOpen(false); setEditOpen(false); break;
    }
  }
  

  /**
   * Adds a client by sending the client data to the server.
   * 
   * @param client the client to add
   */
  const addClient = async (client: Client): Promise<void> => {

    try {
      const response = await postClient(client).unwrap();
      let newClients: Client[] = [...clients, response];
      setClients(newClients);
      form.resetFields();
      setAddOpen(false);
      notification.success({
        message: "Client Created!",
        description: `The client ${response.name} has been created.`
      });
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Creating Client Failed!",
        description: `An error occurred while creating the client`
      });
    }
  }

  const updateClient = async (clientUpdate: Client): Promise<void> => {

    if (!_isUndefined(client)) {

      let nSFTPChannel: SFTPChannel = {
        id: client.sftpChannel?.id || 0,
        targetDirectoryName: clientUpdate.sftpChannel?.targetDirectoryName,
        targetPassword: clientUpdate.sftpChannel?.targetPassword,
        targetUsername: clientUpdate.sftpChannel?.targetUsername,
        fileName: clientUpdate.sftpChannel?.fileName,
      };

      let nRESTChannel: RESTChannel = {
        id: client.restChannel?.id || 0,
        targetGrantType: clientUpdate.restChannel?.targetGrantType,
        targetClientId: clientUpdate.restChannel?.targetClientId,
        targetClientSecret: clientUpdate.restChannel?.targetClientSecret,
        targetUsername: clientUpdate.restChannel?.targetUsername,
        targetPassword: clientUpdate.restChannel?.targetPassword,
        targetAuthEndpoint: clientUpdate.restChannel?.targetAuthEndpoint,
        targetTokenName: clientUpdate.restChannel?.targetTokenName,
        requestMethod: clientUpdate.restChannel?.requestMethod,
        targetEndpoint: clientUpdate.restChannel?.targetEndpoint,
      };

      let nSoapChannel: SOAPChannel = {
        id: client.soapChannel?.id || 0,
        targetEndpoint: clientUpdate.soapChannel?.targetEndpoint,
        targetNamespaceUri: clientUpdate.soapChannel?.targetNamespaceUri,
      };


      let nClient: Client = {
        id: client.id,
        name: clientUpdate.name,
        cronSchedule: clientUpdate.cronSchedule,
        tdSourceURL: clientUpdate.tdSourceURL,
        tdAuthURL: clientUpdate.tdAuthURL,
        tdGrantType: clientUpdate.tdGrantType,
        tdClientId: clientUpdate.tdClientId,
        tdClientSecret: clientUpdate.tdClientSecret,
        tdUsername: clientUpdate.tdUsername,
        tdPassword: clientUpdate.tdPassword,
        payloadType: clientUpdate.payloadType,
        channelType: clientUpdate.channelType,
        targetProtocol: clientUpdate.targetProtocol,
        targetHost: clientUpdate.targetHost,
        targetContextPath: clientUpdate.targetContextPath,
        scheduled: clientUpdate.scheduled,
        sftpChannel: nSFTPChannel,
        restChannel: nRESTChannel,
        soapChannel: nSoapChannel,
      };

      console.log('DEBUGGER - update client', nClient)

      try {
        const response: Client = await putClient(nClient).unwrap();
        let ndx = clients.findIndex(client => client.id === response.id);
        clients[ndx] = response;
        let newClients: Client[] = [...clients];
        setClients(newClients);
        form.resetFields();
        setEditOpen(false);
        notification.success({
          message: "Client Updated!",
          description: `The client ${response.name} has been updated.`
        });

      } catch (e) {
        console.log(e);
        notification.error({
          message: "Updating Client Failed!",
          description: `An error occurred while updating the client.`
        });
      }
    }
  }

  const deleteClient = async (client: Client): Promise<void> => {
    try {
      const response = await removeClient(client).unwrap();

      const temp = [...clients];
      let ndx = temp.findIndex(client => client.id === response.id);
      temp.splice(ndx, 1);
      let newClients: Client[] = [...temp];
      setClients(newClients);
      notification.success({
        message: "Client Deleted!",
        description: `The client ${client.name} has been deleted.`
      });

    } catch (e) {
      console.log(e);
      notification.error({
        message: "Deleting Client Failed!",
        description: `An error occurred while deleting the client.`
      });
    }
  }

  /**
   * Opens the client's data mapping page.
   */
  const openDataMappingPage = (client: Client) => {
    navigate(`/data-mapping/${client?.id}`);
  }

  /**
   * Opens the pdf bulder page.
   */
  const openPdfBuilderPage = (client: Client) => {
    navigate(`/pdf-builder/${client?.id}`);
  }

  /**
   * Schedules a client for fetching data and sending to target client.
   */
  const scheduleClient = async () => {

    try {
      const response = await postScheduleClient(client?.id).unwrap();
      const index = clients?.findIndex((client: any) => client.id === response.id);
      let newClients: Client[] = [...clients];
      newClients[index].scheduled = true;
      setClients(newClients);
      setScheduleOpen(false);
      notification.success({
        message: "Client Scheduled!",
        description: `The client ${response.name} has been scheduled for dispatching.`
      });
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Scheduling Client Failed!",
        description: `An error occurred while scheduling the client`
      });
    }
  }

  /**
   * Cancels the scheduled client from fetching data and sending to target client.
   */
  const unscheduleClient = async () => {

    try {
      const response = await postUnscheduleClient(client?.id).unwrap();
      const index = clients?.findIndex((client: any) => client.id === response.id);
      let newClients: Client[] = [...clients];
      newClients[index].scheduled = false;
      setClients(newClients);
      setUnscheduleOpen(false);
      notification.success({
        message: "Client Schedule Canceled!",
        description: `The schedule of client ${response.name} has been canceled.`
      });
    } catch (e) {
      console.log(e);
      notification.error({
        message: "Cancel Client Schedule Failed!",
        description: `An error occurred while cancelling the client' schedule. ${e}`
      });
    }
  }

  /**
   * Closes the add client modal.
   */
  const closeAddModal = (): void => {
    setAddOpen(false);
  }

  const closeEditModal = (): void => {
    setEditOpen(false);
  }
  /**
   * Cancels the schedule client confirmation modal.
   */
  const closeScheduleModal = (): void => {
    setScheduleOpen(false);
  }

  /**
   * Cancels the client schedule canceling confirmation modal.
   */
  const closeUnscheduleModal = (): void => {
    setUnscheduleOpen(false);
  }

  const onDelete = (client: Client) => {
    modal.confirm({
      title: 'Delete the client',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to delete this client?',
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: () => deleteClient(client),
    });
  };

  /**
   * Handles the click action on the action button of the client
   * in the client list (the 3-dot vertical button on the right-most
   * column)
   * 
   * @param key the key of the menu item that was clicked
   * @param client the client that was clicked
   */

  const handleClientAction = (key: string, client: Client) => {
    setClient(client);
    switch (key) {
      case 'update': setWizardState(0); setEditOpen(true); form.setFieldsValue(client); break;
      case 'delete': onDelete(client); break;
      case 'datamapping': openDataMappingPage(client); break;
      case 'pdfbuilder': openPdfBuilderPage(client); break;
      case 'schedule': setScheduleOpen(true); break;
      case 'unschedule': setUnscheduleOpen(true); break;
    }
  }

  return (
    <>
      {contextHolder}
      <div style={addControlStyle}>
        <h1>Clients</h1>
        <Button
          type="primary"
          onClick={openAddModal}
        >
          Add Client
        </Button>
      </div>

      <Table dataSource={clients} columns={columns} />

      <Modal
        closable={false}
        open={isAddOpen}
        width={600}
        footer={[
          <Button key="cancel" onClick={closeAddModal}>Cancel</Button>,
          <Button type="primary" onClick={backWizardState}>Back</Button>,
          <Button type="primary" onClick={nextWizardState}>Proceed</Button>,
        ]}
      >
        <ClientForm 
          state={wizardState} 
          form={form} 
          addClient={addClient}
        />
      </Modal>

      <Modal
        title={'Client Information'}
        closable={false}
        open={isEditOpen}
        width={600}
        footer={[
          <Button key="cancel" onClick={closeEditModal}>Cancel</Button>,
          <Button type="primary" onClick={backWizardState}>Back</Button>,
          <Button type="primary" onClick={nextWizardState}>Proceed</Button>,
        ]}>
        <ClientForm 
          state={wizardState} 
          form={form} 
          channel={form.getFieldValue('channelType')} 
          tdGrantType={form.getFieldValue('tdGrantType')} 
          updateClient={updateClient}
        />
      </Modal>

      <Modal
        title={'Schedule Client'}
        closable={false}
        open={isScheduleOpen}
        onOk={scheduleClient}
        onCancel={closeScheduleModal}
        width={600}
      >
        <p>Are you sure you want to schedule the client <span style={{ color: 'green' }}>[{client?.name}]</span> for dispatching?</p>
      </Modal>

      <Modal
        title={'Cancel Client Schedule'}
        closable={false}
        open={isUnscheduleOpen}
        onOk={unscheduleClient}
        onCancel={closeUnscheduleModal}
        width={600}
      >
        <p>Are you sure you want to cancel the schedule for client <span style={{ color: 'green' }}>[{client?.name}]</span>?</p>
      </Modal>
    </>
  );
};
export default ClientsPage;

