import { apiForPrivate } from '.';
import { DATA_MAP_ENDPOINT } from '../../config/ap';

const apiDataMaps = apiForPrivate.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    getDataFields: build.mutation({
      query: (clientId) => `${DATA_MAP_ENDPOINT.DATA_MAPS_SOURCE}?clientId=${clientId}`,
    }),
    getDataMap: build.mutation({
      query: (clientId) => `${DATA_MAP_ENDPOINT.DATA_MAPS}?clientId=${clientId}`,
    }),
    saveDataMap: build.mutation({
      query: payload => ({
        url: `${DATA_MAP_ENDPOINT.DATA_MAPS}`,
        method: 'POST',
        body: payload,
      }),
    }),
  })
});

export const {
  useGetDataFieldsMutation,
  useGetDataMapMutation,
  useSaveDataMapMutation
} = apiDataMaps;