import React from 'react';
import { Select, Button, Flex, Typography } from 'antd';
import { FaPlus } from "react-icons/fa";
import { componentHeaderTitleStyle, componentItemButtonStyle, componentItemContainerStyle, componentItemLabelStyle } from './component-style';
import { Client } from '../../model/client-model';

const { Text } = Typography;

interface Props {
  client: Client | undefined;
  clients: Array<Client>;
  setClient: (client: Client) => void;
  addComponent: (componentType: string, isParent: boolean) => void;
}
const ComponentSelector: React.FC<Props> = (props): JSX.Element => {
  const [components] = React.useState<Array<string>>([
    'Field',
    'Column',
    'Table',
    'Space'
  ]);
  const [optList, setOptList] = React.useState<any>([]);

  React.useEffect(() => {
    const reMapToSelectOption = () => {
      let newArray: any = [];
      // eslint-disable-next-line
      props.clients.map((client: Client) => {
        newArray.push({
          label: client.name,
          value: client.id
        })
      })
      setOptList(newArray);
    }
    reMapToSelectOption();
  }, [props]);

  const onChangeClient = (clientId: any) => {
    const ndx = props.clients.findIndex((c) => c.id === clientId);
    props.setClient(props.clients[ndx]);
  }

  return (
    <>
      <Flex
        vertical
        gap={'small'}
        wrap
      >
        <Select
          value={props.client?.id}
          placeholder={'Select Target Client'}
          options={optList}
          onChange={onChangeClient}
        />
        <Text style={componentHeaderTitleStyle} strong>
          Components
        </Text>

        {components.map((component: string) => (
          <Flex
            style={componentItemContainerStyle}
            onClick={() => props.addComponent(component, true)}
            align={'center'}
          >
            <Button
              style={componentItemButtonStyle}
              disabled={!props.client}
              className={'component-button'}
              block
            >
              <Text style={componentItemLabelStyle}>
                {component}
              </Text>
            </Button>
            <FaPlus size={12} color={'#015B93'} />
          </Flex>
        ))}
      </Flex>
    </>
  )
}

export default ComponentSelector;