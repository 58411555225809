
import { v4 as uuidv4 } from 'uuid';
import { ComponentProps } from './model';

const componentCreatorProps = (componentType: string, isParent: boolean, parentKey?: string): ComponentProps => {
  const generateUniqueKey = uuidv4();

  let property: ComponentProps = {
    key: generateUniqueKey,
    type: componentType,
    parentKey: parentKey,
    isParent: isParent,
    label: "",
    value: "",
    grid: "row",
    checkboxAlignment: "left",
    alignment: "left",
    padding: "4",
    distributon: "Manual",
    showCustomPadding: false,
    paddingTop: "0",
    paddingBottom: "0",
    paddingLeft: "0",
    paddingRight: "0",
    borderWidth: "none",
    borderColor: "#231F20",
    hasUnderline: "No",
    width: "0",
    height: "0",
    fontSize: "14",
    fontFamily: "'Trebuchet MS', sans-serif",
    fontWeight: "normal",
    color: "#231F20",
    imageSource: "",
    children: [],

  };

  switch (componentType) {
    case 'Text':
    case 'Value':
      property.checkboxAlignment = "left";
      property.alignment = "left";
      property.padding = "4";
      property.showCustomPadding = false;
      property.paddingTop = "0";
      property.paddingBottom = "0";
      property.paddingLeft = "0";
      property.paddingRight = "0";
      property.fontFamily = "'Trebuchet MS', sans-serif";
      property.fontSize = "14";
      property.fontWeight = "normal";
      property.hasUnderline = "No";
      break;

    case 'Space':
      property.height = "32";
      break;

    case 'Field':
    case 'THeader':
    case 'TCell':
      property.grid = "row";
      property.distributon = "manual";
      property.padding = "4";
      property.showCustomPadding = false;
      property.paddingTop = "0";
      property.paddingBottom = "0";
      property.paddingLeft = "0";
      property.paddingRight = "0";

      property.children.push(componentCreatorProps('Text', false, generateUniqueKey));
      break;

    case 'Table':
      property.children.push(componentCreatorProps('THeader', false, generateUniqueKey));
      property.children.push(componentCreatorProps('TCell', false, generateUniqueKey));
      break;


    case 'Column':
      property.grid = "row";
      property.distributon = "Automatic";
      property.padding = "4";
      property.showCustomPadding = false;
      property.paddingTop = "0";
      property.paddingBottom = "0";
      property.paddingLeft = "0";
      property.paddingRight = "0";
      property.children.push(componentCreatorProps('Field', false, generateUniqueKey));
      break;

    default:
      break;
  }

  return property;
};

export default componentCreatorProps;