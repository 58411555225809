
import React from 'react';
import { Button } from 'antd';
import { buttonStyle, buttoneLabelStyle } from './submit-button-styles';

import { Typography } from 'antd';

const { Text } = Typography;

type ButtonProperties = {
  label: any;
  isLoading: boolean;
};

const SubmitButton: React.FC<ButtonProperties> = ({ label, isLoading }): JSX.Element => {
  return (
    <Button
      style={buttonStyle}
      type="primary"
      loading={isLoading}
      htmlType="submit">
      <Text style={buttoneLabelStyle}>{label}</Text>
    </Button>
  );
};
export default SubmitButton;
