
import React from 'react';
import type { FormProps } from 'antd';
import { useNavigate } from "react-router-dom";
import { Form, Input, message } from 'antd';
import SubmitButton from '../../button/submit-button/submit-button';
import { setSessionCredentialsToStorage } from '../../../redux/action/auth-action';
import { useAppDispatch } from '../../../redux/hooks';
import { useLoginMutation } from '../../../redux/api/auth';
import { AUTH_GRANT_TYPE, CLIENT_ID, CLIENT_SECRET } from '../../../config/ap';

import _isUndefined from 'lodash/isUndefined';
import { LoginPayload } from '../../../model/auth-model';

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

const LoginForm: React.FC<any> = (): JSX.Element => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [login, { isLoading }] = useLoginMutation();

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    if (!_isUndefined(values.username) && !_isUndefined(values.password)) {

      const form: LoginPayload = {
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
        grant_type: AUTH_GRANT_TYPE,
        username: values.username,
        password: values.password,
      }

      const response = await login(form);

      if (response.error) {
        messageApi.open({
          type: 'error',
          content: 'Invalid username/password, Please try again.',
        })
      }

      if (!response.error) {
        dispatch(
          setSessionCredentialsToStorage({
            accessToken: response.data.access_token,
            refreshToken: response.data.refresh_token,
          })
        )
        navigate('/')
      }
    }
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      layout="vertical"
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      requiredMark={false}
    >
      {contextHolder}
      <Form.Item<FieldType>
        label="Username"
        name="username"
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<FieldType>
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <SubmitButton
          key={'login-submit-button'}
          label={'Login to your account'}
          isLoading={isLoading}
        />
      </Form.Item>
    </Form>
  );
};
export default LoginForm;
