import React from 'react';
import { Flex, Typography, Radio } from 'antd';
import { ComponentProps } from '../model';

const { Text } = Typography;

interface Props {
  component: ComponentProps;
  onChangeField: (cP: ComponentProps, field: string, value: any) => void;
}
const PropertyFieldFontAlignment: React.FC<Props> = (props): JSX.Element => {

  if (props.component.type === 'Field' ) {
    return (
      <Flex
        vertical={true}
      >
        <Text
          type="secondary"
          style={{
            color: '#231F20',
            fontSize: '14px',
            fontWeight: '500',
            WebkitUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none'
          }}
        >
          Text Alignment
        </Text>

        <Radio.Group
          style={{ fontSize: "14px" }}
          value={props.component.alignment} onChange={(e) => props.onChangeField(props.component, "alignment", e.target.value)}>
          <Radio.Button value="left">Left</Radio.Button>
          <Radio.Button value="center">Center</Radio.Button>
          <Radio.Button value="right">Right</Radio.Button>
        </Radio.Group>
      </Flex>
    )
  }

  return <></>
}

export default PropertyFieldFontAlignment;