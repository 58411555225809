

const COLOR = {
  LIGHT_BUE_COLOR: '#4096ff',
  WHITE: '#ffffff',
  PRIMARY: '#015B93',
}



export {
  COLOR
}