import { COLOR } from "../../config/color";

const headerStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  color: COLOR.WHITE,
  height: '10vh',
  paddingInline: 48,
  lineHeight: '64px',
  justifyContent: 'space-between'
};

const siderStyle: React.CSSProperties = {
  // textAlign: 'center',
  lineHeight: '120px',
  color: COLOR.WHITE,
  height: '90vh',
  paddingTop: '2rem',
  backgroundColor: COLOR.WHITE,
};

const contentStyle: React.CSSProperties = {
  height: '90vh',
  // padding: '0 48px'
};

const layoutStyle: React.CSSProperties = {
  overflow: 'hidden',
  width: '100%',
  maxWidth: '100%',
  // padding: '24px 0' 
};

const logoStyle: React.CSSProperties = {
  alignItems: 'center',
  display: 'flex',
  height: '2.75rem',
  justifyContent: 'center',
  // overflow: 'hidden',
  // width: '100%',
  // maxWidth: '100%',
};

const titleStyle: React.CSSProperties = {
  color: 'white',
};

const logoutStyle: React.CSSProperties = {
  color: COLOR.WHITE,
};

export {
  headerStyle,
  contentStyle,
  layoutStyle,
  siderStyle,
  logoStyle,
  titleStyle,
  logoutStyle
}