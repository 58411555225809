import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit';
import { apiForPublic, apiForPrivate } from './api';
import authReducer from './slice/auth-slice';

const rootReducer = combineReducers({
  [apiForPublic.reducerPath]: apiForPublic.reducer,
  [apiForPrivate.reducerPath]: apiForPrivate.reducer,
  auth: authReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getMiddleware =>
    getMiddleware({
      serializableCheck: false,
    }).concat(
      apiForPublic.middleware,
      apiForPrivate.middleware
    ),
    devTools: true,
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;