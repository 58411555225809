const componentPropsHeaderButtonDuplicateStyle: React.CSSProperties = {
  borderColor: "#015B93",
  borderWidth: "2px",
  height: "40px"
};

const componentPropsHeaderButtonDuplicateTextStyle: React.CSSProperties = {
  color: "#015B93",
  fontWeight: "700",
  fontSize: "16px"
};


const componentPropsHeaderButtonDeleteStyle: React.CSSProperties = {
  borderColor: "#C3100F",
  borderWidth: "2px",
  height: "40px"
};


const componentPropsHeaderButtonDeleteTextStyle: React.CSSProperties = {
  color: "#C3100F",
  fontWeight: "700",
  fontSize: "16px"
};


const propertyTitleStyle: React.CSSProperties = {
  color: '#231F20',
  fontSize: 16,
  fontWeight: 700,
  marginBottom: "20px",
  WebkitUserSelect: 'none',
  msUserSelect: 'none',
  userSelect: 'none'
};

const propertySubTitleStyle: React.CSSProperties = {
  color: ' #015B93',
  fontSize: 16,
  fontWeight: 700,
  marginBottom: "20px",
  marginLeft: "4px",
  WebkitUserSelect: 'none',
  msUserSelect: 'none',
  userSelect: 'none'
};

export {
  componentPropsHeaderButtonDuplicateStyle,
  componentPropsHeaderButtonDuplicateTextStyle,
  componentPropsHeaderButtonDeleteStyle,
  componentPropsHeaderButtonDeleteTextStyle,
  propertyTitleStyle,
  propertySubTitleStyle
}