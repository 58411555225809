import React from 'react';
import { Provider } from 'react-redux';
import reduxStore from './redux/store';
import AppRoutes from './routes/route';

const App: React.FC = () => (
  <Provider store={reduxStore}>
    <AppRoutes />
  </Provider>
);

export default App;