import React from 'react';
import { Flex, Typography, Divider, Button, Space } from 'antd';
import { ComponentProps } from './model';
import { componentPropsHeaderButtonDeleteStyle, componentPropsHeaderButtonDeleteTextStyle, propertySubTitleStyle, propertyTitleStyle } from './properties-style';
import ItemSelection from './item-selection';
import PropertyFieldDistribution from './properties/property-field-distrubution';
import PropertyFieldGrid from './properties/property-field-grid';
import PropertyFieldLabel from './properties/property-field-label';
import PropertyFieldValue from './properties/property-field-value';
import PropertyFieldFontSize from './properties/property-field-fontSize';
import PropertyFieldFontWeight from './properties/property-field-fontWeight';
import PropertyFieldUnderline from './properties/property-field-underline';
import PropertyFieldFontColor from './properties/property-field-fontColor';
import PropertyFieldFontAlignment from './properties/property-field-textAlignment';
import PropertyFieldPadding from './properties/property-field-padding';
import PropertyFieldBorderWidth from './properties/property-field-borderWidth';
import CustomFieldPadding from './properties/custom-field-padding';
import PropertyFieldCheckboxAlignment from './properties/property-field-checkboxAlignment';
import PropertyFieldBorderColor from './properties/property-field-borderColor';
import PropertyFieldHeight from './properties/property-field-height';
import PropertyFieldFontFamily from './properties/property-field-fontFamily';

const { Text } = Typography;

interface Props {
  dataMapFields: Array<{ value: any, label: any }>;
  component: ComponentProps;
  onChangeField: (cP: ComponentProps, field: string, value: any) => void;
  onChangeComponentType: (cP: ComponentProps, field: string, value: any) => void;
  addItemComponent: (cP: ComponentProps, type: string) => void;
  removeItemComponent: (cP: ComponentProps) => void;
}
const ComponentProperties: React.FC<Props> = (props): JSX.Element => {
  return (
    <>
      {props.component.isParent && (
        <>
          <Flex
            vertical={true}
            gap={12}
          >
            {/* <Button
              style={componentPropsHeaderButtonDuplicateStyle}
              onClick={() => { }}
              block
            >
              <Text style={componentPropsHeaderButtonDuplicateTextStyle}>
                Duplicate
              </Text>
            </Button> */}

            <Button
              style={componentPropsHeaderButtonDeleteStyle}
              onClick={() => props.removeItemComponent(props.component)}
              block
            >
              <Text style={componentPropsHeaderButtonDeleteTextStyle}>
                Delete
              </Text>
            </Button>
          </Flex>

          <Divider style={{ color: '#4C4C4C', }} />
        </>
      )}

      <Text type="secondary" style={propertyTitleStyle}>
        Properties |
        <Text type="secondary" style={propertySubTitleStyle}>
          {props.component.type}
        </Text>
      </Text>

      <Space style={{ width: '100%' }} direction="vertical" size="middle">
        <ItemSelection
          component={props.component}
          addItemComponent={props.addItemComponent}
          removeItemComponent={props.removeItemComponent}
          onChangeField={props.onChangeComponentType}
        />

        <PropertyFieldDistribution component={props.component} onChangeField={props.onChangeField} />
        <PropertyFieldGrid component={props.component} onChangeField={props.onChangeField} />
        <PropertyFieldLabel component={props.component} onChangeField={props.onChangeField} />
        <PropertyFieldValue dataMapFields={props.dataMapFields} component={props.component} onChangeField={props.onChangeField} />
        <PropertyFieldFontFamily component={props.component} onChangeField={props.onChangeField} />
        <PropertyFieldFontSize component={props.component} onChangeField={props.onChangeField} />
        <PropertyFieldFontWeight component={props.component} onChangeField={props.onChangeField} />
        <PropertyFieldFontColor component={props.component} onChangeField={props.onChangeField} />
        <PropertyFieldFontAlignment component={props.component} onChangeField={props.onChangeField} />
        <PropertyFieldUnderline component={props.component} onChangeField={props.onChangeField} />
        <PropertyFieldBorderColor component={props.component} onChangeField={props.onChangeField} />
        <PropertyFieldBorderWidth component={props.component} onChangeField={props.onChangeField} />
        <PropertyFieldCheckboxAlignment component={props.component} onChangeField={props.onChangeField} />
        <PropertyFieldHeight component={props.component} onChangeField={props.onChangeField} />
        <PropertyFieldPadding component={props.component} onChangeField={props.onChangeField} />
        <CustomFieldPadding component={props.component} onChangeField={props.onChangeField} />
      </Space>
    </>
  )
}

export default ComponentProperties;