import React from 'react';
import { Flex, Typography, Select } from 'antd';
import { ComponentProps } from '../model';

const { Text } = Typography;

interface Props {
  component: ComponentProps;
  onChangeField: (cP: ComponentProps, field: string, value: any) => void;
}
const PropertyFieldFontSize: React.FC<Props> = (props): JSX.Element => {

  if (props.component.type === 'Text' ||
    props.component.type === 'Value' ||
    props.component.type === 'Checkbox'
  ) {
    return (
      <Flex
        vertical={true}
      >
        <Text
          type="secondary"
          style={{
            color: '#231F20',
            fontSize: '14px',
            fontWeight: '500',
            WebkitUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none'
          }}
        >
          Font Size
        </Text>
        <Select
          style={{ fontSize: "14px", width: '100%' }}
          value={props.component.fontSize}
          onChange={(value: string) => props.onChangeField(props.component, "fontSize", value)}
          options={[
            { value: '8', label: '8' },
            { value: '10', label: '10' },
            { value: '12', label: '12' },
            { value: '14', label: '14' },
            { value: '16', label: '16' },
            { value: '18', label: '18' },
            { value: '20', label: '20' },
            { value: '24', label: '24' },
            { value: '28', label: '28' },
            { value: '32', label: '32' },
            { value: '64', label: '64' },
          ]}
          suffixIcon={<Text style={{ color: '#4C4C4C' }}>px</Text>}
        />
      </Flex>
    )
  }

  return <></>
}

export default PropertyFieldFontSize;