import React from 'react';
import { Flex, Typography, Select } from 'antd';
import { ComponentProps } from '../model';

const { Text } = Typography;

interface Props {
  component: ComponentProps;
  onChangeField: (cP: ComponentProps, field: string, value: any) => void;
}
const PropertyFieldGrid: React.FC<Props> = (props): JSX.Element => {

  if (props.component.type === 'Flex') {
    return (
      <Flex
        vertical={true}
      >
        <Text
          type="secondary"
          style={{
            color: '#231F20',
            fontSize: '14px',
            fontWeight: '500',
            WebkitUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none'
          }}
        >
          Grid
        </Text>
        <Select
          style={{ fontSize: "14px" }}
          value={props.component.grid}
          onChange={(value: string) => props.onChangeField(props.component, "grid", value)}
          options={[
            { value: 'row', label: 'Row' },
            { value: 'column', label: 'Column' },
          ]}
        />
      </Flex>
    )
  }

  return <></>
}

export default PropertyFieldGrid;