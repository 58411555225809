import { COLOR } from "../../../config/color";

const buttonStyle: React.CSSProperties = {
  backgroundColor: COLOR.PRIMARY,
  padding: '0.5rem 1rem',
  height: 'auto',
  borderRadius: '8px'
};

const buttoneLabelStyle: React.CSSProperties = {
  color: 'rgb(255,255,255)',
  fontSize: '1rem',
};


export {
  buttonStyle,
  buttoneLabelStyle
}