const mainContainerStyle: React.CSSProperties = {
  backgroundColor: "#F5F5F5",
  height: "100svh",
};

const xlLoginImageStyle: React.CSSProperties = {
  minWidth: 512,
  objectFit: "cover",
  borderRadius: 24,
};

const smLoginImageStyle: React.CSSProperties = {
  objectFit: "cover",
  borderRadius: 24,
};

const loginFormContainerStyle: React.CSSProperties = {
  backgroundColor: "#FFF",
  height: "100%",
  paddingBottom: 112,
};

const welcomeTextStyle: React.CSSProperties = {
  marginBottom: 16,
};

export {
  mainContainerStyle,
  xlLoginImageStyle,
  smLoginImageStyle,
  loginFormContainerStyle,
  welcomeTextStyle,
};
