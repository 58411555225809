import React from 'react';
import { Flex, Typography, Input } from 'antd';
import { SketchPicker } from 'react-color';
import { ComponentProps } from '../model';
import { FaPalette } from "react-icons/fa6";

const { Text } = Typography;

interface Props {
  component: ComponentProps;
  onChangeField: (cP: ComponentProps, field: string, value: any) => void;
}
const PropertyFieldFontColor: React.FC<Props> = (props): JSX.Element => {

  const [fColor, setFColor] = React.useState('#fff');

  const onChangeColorComplete = (color: any) => {
    props.onChangeField(props.component, "color", color.hex)
    // setFColor(color.hex);
  }

  if (props.component.type === 'Text' ||
    props.component.type === 'Value' ||
    props.component.type === 'Checkbox'
  ) {
    return (

      <Flex
        gap={12}
        vertical={true}
      >
        <Text
          type="secondary"
          style={{
            color: '#231F20',
            fontSize: '14px',
            fontWeight: '500',
            WebkitUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none'
          }}
        >
          Font Color
        </Text>
        <SketchPicker
          color={props.component.color}
          onChangeComplete={onChangeColorComplete}
        />
      </Flex>
    )
  }

  return <></>
}

export default PropertyFieldFontColor;