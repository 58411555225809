import React from 'react';
import { ComponentProps } from './model';
import { pageContentStyle } from './content-style';

import "./style.css";

interface Props {
  component: ComponentProps | undefined;
  components: Array<ComponentProps>;
  setSelectedComponent: (componentProps: any) => void;
}
const PDFContent: React.FC<Props> = (props): JSX.Element => {
  const [hoverText, setHoverText] = React.useState<boolean>(false);
  const [hoverValue, setHoverValue] = React.useState<boolean>(false);
  const [hoverCheckbox, setHoverCheckbox] = React.useState<boolean>(false);

  const [hoverSpace, setHoverSpace] = React.useState<boolean>(false);

  const [hoverField, setHoverField] = React.useState<boolean>(false);
  const [hoverFlex, setHoverFlex] = React.useState<boolean>(false);
  const [hoverTable, setHoverTable] = React.useState<boolean>(false);

  const [hoverTableHeader, setHoverTableHeader] = React.useState<boolean>(false);
  const [hoverTableCell, setHoverTableCell] = React.useState<boolean>(false);

  const componentRenderer = (cP: ComponentProps) => {

    switch (cP.type) {
      case 'Text':
        return (
          <span
            key={`text-${cP.key}`}
            className={(hoverText) ? 'text' : ''}
            onMouseEnter={() => setHoverText(true)}
            onMouseLeave={() => setHoverText(false)}
            onDoubleClick={(e) => (hoverText) ? props.setSelectedComponent(cP) : e.stopPropagation()}
            style={{
              fontFamily: cP.fontFamily,
              fontSize: `${cP.fontSize}px`,
              fontWeight: cP.fontWeight,
              color: cP.color || "#231F20",
              padding: cP.padding === "Custom" ? `${cP.paddingTop}px ${cP.paddingRight}px ${cP.paddingBottom}px ${cP.paddingLeft}px ` : `${cP.padding}px`,
              backgroundColor: props.component && props.component.key === cP.key ? 'rgba(1, 91, 147, 0.3)' : 'unset',
            }}
          >
            {cP?.label || "Text"}
          </span>
        )

      case 'Value':
        return (
          <span
            key={`value-${cP.key}`}
            className={(hoverValue) ? 'value' : ''}
            onMouseEnter={() => setHoverValue(true)}
            onMouseLeave={() => setHoverValue(false)}
            onDoubleClick={(e) => (hoverValue) ? props.setSelectedComponent(cP) : e.stopPropagation()}
            style={{
              fontFamily: cP.fontFamily,
              fontSize: `${cP.fontSize}px`,
              fontWeight: cP.fontWeight,
              color: cP.color || "#231F20",
              border: '1px solid black',
              padding: cP.padding === "Custom" ? `${cP.paddingTop}px ${cP.paddingRight}px ${cP.paddingBottom}px ${cP.paddingLeft}px ` : `${cP.padding}px`,
              backgroundColor: props.component && props.component.key === cP.key ? 'rgba(1, 91, 147, 0.3)' : 'unset',
            }}
          >
            <span style={{ borderBottom: cP.hasUnderline === 'Yes' ? "1px solid black" : "unset", }}>{cP?.value || "Value"}</span>
          </span>
        )

      case 'Checkbox':
        return (
          <span
            key={`checkbox-${cP.key}`}
            className={(hoverCheckbox) ? 'checkbox' : ''}
            onMouseOver={() => setHoverCheckbox(true)}
            onMouseOut={() => setHoverCheckbox(false)}
            onDoubleClick={(e) => (hoverCheckbox) ? props.setSelectedComponent(cP) : e.stopPropagation()}
            style={{
              fontFamily: cP.fontFamily,
              fontSize: `${cP.fontSize}px`,
              fontWeight: cP.fontWeight,
              color: cP.color || "#231F20",
              padding: cP.padding === "Custom" ? `${cP.paddingTop}px ${cP.paddingRight}px ${cP.paddingBottom}px ${cP.paddingLeft}px ` : `${cP.padding}px`,
              backgroundColor: props.component && props.component.key === cP.key ? 'rgba(1, 91, 147, 0.3)' : 'unset',
            }}
          >
            {cP.checkboxAlignment === 'left' && (
              <span>
                <input type="checkbox" style={{ marginRight: "4px" }} ></input>
                {cP?.label || "LABEL"}
              </span>
            )}

            {cP.checkboxAlignment === 'right' && (
              <span style={{ display: 'flex' }}>
                {cP?.label || "LABEL"}
                <input type="checkbox" style={{ marginLeft: "4px" }} ></input>
              </span>
            )}
          </span>
        )

      case 'Space':
        return (
          <div
            key={`space-${cP.key}`}
            className={(hoverSpace && !hoverText && !hoverValue && !hoverCheckbox) ? 'field' : ''}
            onMouseEnter={() => setHoverSpace(true)}
            onMouseLeave={() => setHoverSpace(false)}
            onDoubleClick={(e) => (hoverSpace && !hoverText && !hoverValue && !hoverCheckbox) ? props.setSelectedComponent(cP) : e.stopPropagation()}
            style={{
              margin: 0,
              padding: 0,
              height: `${cP.height}px`,
              backgroundColor: props.component && props.component.key === cP.key ? 'rgba(1, 91, 147, 0.3)' : 'unset',
            }}
          >

          </div>
        )

      case 'Field':
        return (
          <p
            key={`field-${cP.key}`}
            className={(hoverField && !hoverText && !hoverValue && !hoverCheckbox) ? 'field' : ''}
            onMouseEnter={() => setHoverField(true)}
            onMouseLeave={() => setHoverField(false)}
            onDoubleClick={(e) => (hoverField && !hoverText && !hoverValue && !hoverCheckbox) ? props.setSelectedComponent(cP) : e.stopPropagation()}
            style={{
              margin: 0,
              height: 'auto',
              textIndent: "0pt",
              textAlign: cP.alignment || "left",
              padding: cP.padding === 'Custom' ? `${cP.paddingTop}px ${cP.paddingRight}px ${cP.paddingBottom}px ${cP.paddingLeft}px ` : `${cP.padding}px`,
              border: cP.borderWidth !== 'none' ? `${cP.borderWidth}px solid ${cP.borderColor}` : 'unset',
              backgroundColor: props.component && props.component.key === cP.key ? 'rgba(1, 91, 147, 0.3)' : 'unset',
            }}
          >
            {cP.children.map((componentChildren: ComponentProps,) => componentRenderer(componentChildren))}
          </p>
        )

      case 'THeader':
        return (
          <td
            key={`table-header-${cP.key}`}
            className={(hoverTableHeader && !hoverText && !hoverValue && !hoverCheckbox) ? 'table-header' : ''}
            onMouseEnter={() => setHoverTableHeader(true)}
            onMouseLeave={() => setHoverTableHeader(false)}
            onDoubleClick={(e) => (hoverTableHeader && !hoverText && !hoverValue && !hoverCheckbox) ? props.setSelectedComponent(cP) : e.stopPropagation()}
            style={{
              fontFamily: cP.fontFamily,
              fontSize: `${cP.fontSize}px`,
              fontWeight: cP.fontWeight,
              color: cP.color || "#231F20",
              padding: cP.padding === "Custom" ? `${cP.paddingTop}px ${cP.paddingRight}px ${cP.paddingBottom}px ${cP.paddingLeft}px ` : `${cP.padding}px`,
              border: "1px solid #231F20",
              backgroundColor: props.component && props.component.key === cP.key ? 'rgba(1, 91, 147, 0.3)' : '#CCCCCC',
            }}
          >
            {cP.children.map((componentChildren: ComponentProps,) => componentRenderer(componentChildren))}
          </td>
        )

      case 'TCell':
        return (
          <td
            key={`table-cell-${cP.key}`}
            className={(hoverTableCell && !hoverText && !hoverValue && !hoverCheckbox) ? 'table-cell' : ''}
            onMouseEnter={() => setHoverTableCell(true)}
            onMouseLeave={() => setHoverTableCell(false)}
            onDoubleClick={(e) => (hoverTableCell && !hoverText && !hoverValue && !hoverCheckbox) ? props.setSelectedComponent(cP) : e.stopPropagation()}
            style={{
              fontFamily: cP.fontFamily,
              fontSize: `${cP.fontSize}px`,
              fontWeight: cP.fontWeight,
              color: cP.color || "#231F20",
              padding: cP.padding === "Custom" ? `${cP.paddingTop}px ${cP.paddingRight}px ${cP.paddingBottom}px ${cP.paddingLeft}px ` : `${cP.padding}px`,
              border: "1px solid #231F20",
              backgroundColor: props.component && props.component.key === cP.key ? 'rgba(1, 91, 147, 0.3)' : 'unset',
            }}
          >
            {cP.children.map((componentChildren: ComponentProps,) => componentRenderer(componentChildren))}
          </td>
        )

      case 'Table':
        return (
          <div
            key={`table-${cP.key}`}
            className={(hoverTable && !hoverTableHeader && !hoverTableCell) ? 'table' : ''}
            onMouseEnter={() => setHoverTable(true)}
            onMouseLeave={() => setHoverTable(false)}
            onDoubleClick={(e) => (hoverTable && !hoverTableHeader && !hoverTableCell) ? props.setSelectedComponent(cP) : e.stopPropagation()}
            style={{
              padding: "4px",
              color: '#231F20',
              backgroundColor: props.component && props.component.key === cP.key ? 'rgba(1, 91, 147, 0.3)' : 'unset',
            }}
          >
            <table
              width={'100%'}
              style={{
                border: '1px solid black',
                borderCollapse: 'collapse',
              }}
            >
              <tr>
                {cP.children.map((componentChildren: ComponentProps,) => {
                  if (componentChildren.type === 'THeader') {
                    return componentRenderer(componentChildren)
                  }
                  return <></>
                })}
              </tr>
              <tr>
                {cP.children.map((componentChildren: ComponentProps,) => {
                  if (componentChildren.type === 'TCell') {
                    return componentRenderer(componentChildren)
                  }
                  return <></>
                })}
              </tr>
            </table>
          </div>
        )

      case 'Column':
        return (
          <div
            key={`column-${cP.key}`}
            className={(hoverFlex && !hoverField && !hoverText && !hoverValue && !hoverCheckbox) ? 'column' : ''}
            onMouseEnter={() => setHoverFlex(true)}
            onMouseLeave={() => setHoverFlex(false)}
            onDoubleClick={(e) => (hoverFlex && !hoverField && !hoverText && !hoverValue && !hoverCheckbox) ? props.setSelectedComponent(cP) : e.stopPropagation()}
            style={{
              padding: cP.padding === 'Custom' ? `${cP.paddingTop}px ${cP.paddingRight}px ${cP.paddingBottom}px ${cP.paddingLeft}px ` : `${cP.padding}px`,
              backgroundColor: props.component && props.component.key === cP.key ? 'rgba(1, 91, 147, 0.3)' : 'unset',
            }}
          >

            {cP.distributon === "Automatic" && (
              <div style={{ display: "flex" }}>
                {cP.children.map((componentChildren: ComponentProps,) => {
                  return (
                    <div style={{ flex: 1 }}>{componentRenderer(componentChildren)}</div>
                  )
                })}
              </div>
            )}

            {cP.distributon === "Manual" && (
              <div style={{ display: "flex" }}>
                {cP.children.map((componentChildren: ComponentProps,) => componentRenderer(componentChildren))}
              </div>
            )}
          </div>
        )
    }
  }

  return (
    <div
      id={'canvas'}
      className={'canvas'}
      style={pageContentStyle}
      onClick={() => props.setSelectedComponent(undefined)}
    >
      {props.components?.map((component: ComponentProps) => componentRenderer(component))}
    </div>
  )
}

export default PDFContent;