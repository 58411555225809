import { apiForPrivate } from '.';
import { COMPONENT_MAP_ENDPOINT } from '../../config/ap';

const apiComponentMaps = apiForPrivate.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    getComponentMap: build.mutation({
      query: (clientId) => `${COMPONENT_MAP_ENDPOINT.COMPONENT_MAP}?clientId=${clientId}`,
    }),

    saveComponentMap: build.mutation({
      query: ({ clientId, htmlContent, payload  }) => ({
        url: `${COMPONENT_MAP_ENDPOINT.COMPONENT_MAP}`,
        method: 'POST',
        body: payload,
        params: {
          clientId: clientId,
          htmlContent: htmlContent
        }
      }),
    }),
  })
});

export const {
  useGetComponentMapMutation,
  useSaveComponentMapMutation,
} = apiComponentMaps;