import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import SignIn from '../page/signin-page';
import ProtectedRoute from './protected-route';
import DashboardPage from '../page/dashboard-page';
import HomeLayout from '../layout/home-layout/home-layout';
import ClientsPage from '../page/clients-page';
import DataMappingPage from '../page/data-mapping-page';
import PDFBuilderPage from '../page/pdf-builder-page';
import LoginPage from '../page/login-page';
import UsersPage from '../page/admin-page';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={<LoginPage />}
        />

        {/* <Route element={<ProtectedRoute />} >
          <Route
            element={<HomeLayout />}>
            <Route
              path="/"
              element={<DashboardPage />} />
          </Route>

          <Route
            element={<HomeLayout />}>
            <Route
              path="/dashboard"
              element={<DashboardPage />} />
          </Route>
          <Route
            element={<HomeLayout />}>
            <Route
              path="/clients"
              element={<ClientsPage />} />
          </Route>
          <Route
            element={<HomeLayout />}>
            <Route
              path="/data-mapping/:clientId"
              element={<DataMappingPage />} />
          </Route>
          <Route
            element={<HomeLayout />}>
            <Route
              path="/pdf-builder/:clientId"
              element={<PDFBuilderPage />} />
          </Route>

          <Route
            element={<HomeLayout />}>
            <Route
              path="/pdf-builder"
              element={<PDFBuilderPage />} />
          </Route>

          <Route
            element={<HomeLayout />}>
            <Route
              path="/users"
              element={<PDFBuilderPage />} />
          </Route>
        </Route> */}

        <Route element={<ProtectedRoute />} >
          <Route
            element={<HomeLayout />}>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/clients" element={<ClientsPage />} />
            <Route path="/data-mapping/:clientId" element={<DataMappingPage />} />
            <Route path="/pdf-builder/:clientId" element={<PDFBuilderPage />} />
            <Route path="/pdf-builder" element={<PDFBuilderPage />} />
            <Route path="/users" element={<UsersPage />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
};

export default AppRoutes;