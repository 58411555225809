import React from 'react';
import { componentSyle } from './aligner-layout-style';

interface Props { children?: Array<JSX.Element> | JSX.Element; }
const ComponentLayout: React.FC<Props> = ({ children }): JSX.Element => {
  return (
    <div
      style={componentSyle}
    >{children}</div>
  )
}

export default ComponentLayout;