import React from 'react';
import { Flex, Typography, Select } from 'antd';
import { ComponentProps } from '../model';

const { Text } = Typography;

interface Props {
  component: ComponentProps;
  onChangeField: (cP: ComponentProps, field: string, value: any) => void;
}
const PropertyFieldPadding: React.FC<Props> = (props): JSX.Element => {

  if (props.component.type === 'Text' ||
    props.component.type === 'Value' ||
    props.component.type === 'Checkbox' ||
    props.component.type === 'Field'
  ) {
    return (
      <Flex
        vertical={true}
      >
        <Text
          type="secondary"
          style={{
            color: '#231F20',
            fontSize: '14px',
            fontWeight: '500',
            WebkitUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none'
          }}
        >
          Padding
        </Text>
        <Flex  gap={6} align='center'>
          <Select
            style={{ fontSize: "14px", width: '100%' }}
            value={props.component.padding}
            onChange={(value: string) => props.onChangeField(props.component, "padding", value)}
            options={[
              { value: '4', label: '4' },
              { value: '8', label: '8' },
              { value: '12', label: '12' },
              { value: '16', label: '16' },
              { value: '20', label: '20' },
              { value: '24', label: '24' },
              { value: '28', label: '28' },
              { value: '32', label: '32' },
              { value: '64', label: '64' },
            ]}
            suffixIcon={<Text style={{ color: '#4C4C4C' }}>px</Text>}
          />
          <Text
            onClick={() => props.onChangeField(props.component, "showCustomPadding", !props.component.showCustomPadding)}
            type="secondary"
            style={{
              color: '#015B93',
              fontSize: '14px',
              fontWeight: '500',
              width: "50px",
              cursor: 'pointer',
              WebkitUserSelect: 'none',
              msUserSelect: 'none',
              userSelect: 'none'
            }}
          >
            {!props.component.showCustomPadding ? "More" : "Less"}
          </Text>
        </Flex>
      </Flex>
    )
  }

  return <></>
}

export default PropertyFieldPadding;