import React from 'react';
import { editorStyle } from './aligner-layout-style';

interface Props { children?: Array<JSX.Element> | JSX.Element; }
const EditorLayout: React.FC<Props> = ({ children }): JSX.Element => {
  return (
    <div
      style={editorStyle}
    >{children}</div>
  )
}

export default EditorLayout;