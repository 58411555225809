const mainContainerStyle: React.CSSProperties = {
  height: "100%",
  font: "normal normal 1rem/1.4 Roboto, Helvetica, Arial, sans-serif",
  margin: 0
};

const canvasStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  boxSizing: "border-box",
  position: "relative",
  backgroundColor: "#CCCCCC",
  marginLeft: 32,
  padding: "8px",
  width: '100%',
  height: '80vh ',
  transition: ".2s",
  overflowY: "scroll",
};

const editorStyle: React.CSSProperties = {
  position: "relative",
  display: "flex",
  flexDirection: "row",
  height: "100%",
  minHeight: "600px",
  color: "#fff",
  fontFamily: "Roboto, Arial, sans-serif",
  overflow: "hidden",
  lineHeight: "1.555",
  paddingTop: 0,
  transition: "all .3s ease"
};

const propertyStyle: React.CSSProperties = {
  position: "relative",
  width: "264px",
  height: '80vh ',
  flexDirection: "column",
  flex: "0 0 264px",
  paddingTop: "12px",
  boxSizing: "border-box",
  transition: "all .3s",
  transform: "translateX(-100 %)",
  padding: "24px 24px 16px 22px",
  gap: "24px",
  background: "#FFFFFF",
  overflowY: "scroll",
};

const componentSyle: React.CSSProperties = {
  position: "relative",
  width: "200px",
  height: '80vh ',
  flexDirection: "column",
  flex: "0 0 200px",
  paddingTop: "12px",
  boxSizing: "border-box",
  transition: "all .3s",
  transform: "translateX(-100 %)",
  padding: "24px 24px 16px 22px",
  gap: "24px",
  background: "#FFFFFF"
};

export {
  mainContainerStyle,
  editorStyle,
  canvasStyle,
  propertyStyle,
  componentSyle
}