import React from 'react';
import { Flex, Typography, Select } from 'antd';
import { ComponentProps } from '../model';
import { FaPalette } from "react-icons/fa6";

const { Text } = Typography;

interface Props {
  component: ComponentProps;
  onChangeField: (cP: ComponentProps, field: string, value: any) => void;
}
const PropertyFieldBorderWidth: React.FC<Props> = (props): JSX.Element => {
  if (props.component.type === 'Field') {
    return (
      <Flex
        vertical={true}
      >
        <Text
          type="secondary"
          style={{
            color: '#231F20',
            fontSize: '14px',
            fontWeight: '500',
            WebkitUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none'
          }}
        >
          Border Width
        </Text>
        
        <Select
          style={{ fontSize: "14px" }}
          value={props.component.borderWidth}
          onChange={(value: string) => props.onChangeField(props.component, "borderWidth", value)}
          options={[
            { value: 'none', label: 'None' },
            { value: '1', label: '1px' },
            { value: '2', label: '2px' },
            { value: '3', label: '3px' },
            { value: '4', label: '4px' },
            { value: '5', label: '5px' },
          ]}
        />
      </Flex>
    )
  }

  return <></>
}

export default PropertyFieldBorderWidth;