
const pageContentStyle: React.CSSProperties = {

  backgroundColor: '#FFFFFF',
  width: "100%", //640px
  height: "2200px",
  cursor: "pointer",
  WebkitTouchCallout: "none",
  WebkitUserSelect: "none",
  KhtmlUserSelect: "none",
  MozUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
};

export {
  pageContentStyle
}