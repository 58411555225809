import React from 'react';
import { propertyStyle } from './aligner-layout-style';

interface Props { children?: Array<JSX.Element> | JSX.Element; }
const PropertyLayout: React.FC<Props> = ({ children }): JSX.Element => {
  return (
    <div
      style={propertyStyle}
    >{children}</div>
  )
}

export default PropertyLayout;