import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SERVER_DOMAIN } from '../../config/ap';
import { RootState } from '../store';

const BASE_QUERY = fetchBaseQuery({
  baseUrl: SERVER_DOMAIN,
});

const BASE_QUERY_AUTH = fetchBaseQuery({
  baseUrl: SERVER_DOMAIN,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.session.accessToken;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    headers.set('Content-Type', 'application/json');
    return headers;
  },
});

const apiForPublic = createApi({
  reducerPath: 'apiPublic',
  baseQuery: BASE_QUERY,
  endpoints: () => ({}),
});

// For api calls that require authorization header
const apiForPrivate = createApi({
  reducerPath: 'apiPrivate',
  baseQuery: BASE_QUERY_AUTH,
  endpoints: () => ({}),
});

export { apiForPublic, apiForPrivate }