import React from 'react';
import { Space, Flex, Typography, Input } from 'antd';
import { ComponentProps } from '../model';
const { Text } = Typography;

interface Props {
  component: ComponentProps;
  onChangeField: (cP: ComponentProps, field: string, value: any) => void;
}
const CustomFieldPadding: React.FC<Props> = (props): JSX.Element => {

  if (props.component.showCustomPadding && props.component.padding === 'Custom') {
    return (
      <Space.Compact
        size="large"
      >
        <Flex
          align='center'
          vertical={true}
        >
          <Input
            style={{ textAlign: "center", fontSize: "14px" }}
            value={props.component.paddingTop}
            onChange={(e: any) => props.onChangeField(props.component, "paddingTop", e.target.value)}
          />
          <Text
            type="secondary"
            style={{
              color: '#231F20',
              fontSize: '11px',
              fontWeight: '500',
              textAlign: 'center',
              WebkitUserSelect: 'none',
              msUserSelect: 'none',
              userSelect: 'none'
            }}
          >
            Top
          </Text>
        </Flex>
        <Flex
          vertical={true}
        >
          <Input
            style={{ textAlign: "center", fontSize: "14px" }}
            value={props.component.paddingRight}
            onChange={(e: any) => props.onChangeField(props.component, "paddingRight", e.target.value)}
          />
          <Text
            type="secondary"
            style={{
              color: '#231F20',
              fontSize: '11px',
              fontWeight: '500',
              textAlign: 'center',
              WebkitUserSelect: 'none',
              msUserSelect: 'none',
              userSelect: 'none'
            }}
          >
            Right
          </Text>
        </Flex>
        <Flex
          vertical={true}
        >
          <Input
            style={{ textAlign: "center", fontSize: "14px" }}
            value={props.component.paddingBottom}
            onChange={(e: any) => props.onChangeField(props.component, "paddingBottom", e.target.value)}
          />
          <Text
            type="secondary"
            style={{
              color: '#231F20',
              fontSize: '11px',
              fontWeight: '500',
              textAlign: 'center',
              WebkitUserSelect: 'none',
              msUserSelect: 'none',
              userSelect: 'none'
            }}
          >
            Bottom
          </Text>
        </Flex>
        <Flex
          vertical={true}
        >
          <Input
            style={{ textAlign: "center", fontSize: "14px" }}
            value={props.component.paddingLeft}
            onChange={(e: any) => props.onChangeField(props.component, "paddingLeft", e.target.value)}
          />
          <Text

            type="secondary"
            style={{
              color: '#231F20',
              fontSize: '11px',
              fontWeight: '500',
              textAlign: 'center',
              WebkitUserSelect: 'none',
              msUserSelect: 'none',
              userSelect: 'none'
            }}
          >
            Left
          </Text>
        </Flex>
      </Space.Compact>
    )
  }

  return <></>
}

export default CustomFieldPadding;