import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { Button, Flex, Layout, Menu, Modal, Typography } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { useAppDispatch } from '../../redux/hooks';
import { removeSessionCredentialsToStorage } from '../../redux/action/auth-action';
import { headerStyle, contentStyle, siderStyle, layoutStyle, logoStyle, logoutStyle, titleStyle } from './home-layout-style';
import { AiOutlineDashboard } from 'react-icons/ai';
import { FaHandshake, FaFilePdf, FaUser } from "react-icons/fa";

import logo from '../../assets/images/logo.png';
const {Title} = Typography;

const { Header, Content, Sider, Footer } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const HomeLayout = () => {

  const [isLogoutOpen, setLogoutOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [current, setCurrent] = useState('Dashboard');

  const items: MenuItem[] = [
    { key: 'Dashboard', icon: <AiOutlineDashboard />, label: 'Dashboard' },
    { key: 'Clients', icon: <FaHandshake />, label: 'Clients' },
    { key: 'PDF-Builder', icon: <FaFilePdf />, label: 'PDF Builder' },
    { key: 'Users', icon: <FaUser />, label: 'Admin Users' },
  ];

  useEffect(() => {
    if (location.pathname === '/') {
      setCurrent('Dashboard');
    } else if (location.pathname === '/dashboard') {
      setCurrent('Dashboard');
    } else if (location.pathname === '/clients') {
      setCurrent('Clients');
    } else if (location.pathname === '/pdf-builder') {
      setCurrent('PDF-Builder');
    } else if (location.pathname === '/users') {
      setCurrent('Users');
    }


  }, [location.pathname]);

  const onClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case 'Dashboard':
        navigate('/dashboard');
        break;

      case 'Clients':
        navigate('/clients');
        break;

      case 'PDF-Builder':
        navigate('/pdf-builder');
        break;

      case 'Users':
        navigate('/users');
        break;

      default:
        navigate('/');
        break;
    }
    setCurrent(e.key);
  };

  const onLogout = () => {
    setLogoutOpen(true);
  };

  const logout = () => {
    setLogoutOpen(false);
    dispatch(removeSessionCredentialsToStorage());
    navigate('/login')
  }

  return (<><Flex gap="middle" vertical>
      <Layout>
        <Header style={headerStyle}>
          <div style={logoStyle}>
          <Title level={3} style={titleStyle}>
            TrustedSync
          </Title>
            {/* <img src={logo} /> */}
          </div>
          <Button onClick={() => onLogout()} style={logoutStyle} type="link" size={'middle'}>
            <LogoutOutlined />
            Logout
          </Button>
        </Header>
        <Content style={contentStyle}>
          <Layout
            style={layoutStyle}
          >
            <Sider theme='light' style={siderStyle} width={200}>
              <Menu
                mode="inline"
                onClick={onClick}
                openKeys={['sub1']}
                selectedKeys={[current]}
                items={items}
                theme='light'
                getPopupContainer={(node) => node.parentNode as HTMLElement}
              />
            </Sider>
            <Content style={{ padding: '0 24px', minHeight: 280 }}>
              {/* <Breadcrumb
              style={{ margin: '16px 0' }}
              items={crumbTrail}
            /> */}
              <Outlet />
            </Content>
          </Layout>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Ant Design ©{new Date().getFullYear()} Created by Ant UED
        </Footer>
      </Layout>
    </Flex>
    <Modal
        title={'Logout from Trusted Sync'}
        closable={false}
        open={isLogoutOpen}
        onOk={logout}
        onCancel={() => setLogoutOpen(false)}
        width={400}
      >
        <p>Are you sure you want to logout?</p>
      </Modal>
    </>
  );
};

export default HomeLayout;
