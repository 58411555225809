import { apiForPublic } from '.';
import { LOGIN_ENDPOINT } from '../../config/ap';
import { LoginPayload, LoginResponse } from '../../model/auth-model';

const apiAuth = apiForPublic.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    login: build.mutation<LoginResponse, LoginPayload>({
      query: payload => ({
        url: LOGIN_ENDPOINT.AUTH,
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: new URLSearchParams(payload),
      }),
    }),
  })
});

export const {
  useLoginMutation
} = apiAuth;