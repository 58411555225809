import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { AuthState, SessionCredential } from "../../model/auth-model";

const initialState: AuthState = {
  session: {
    accessToken: null,
    refreshToken: null,
  }
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSession(state, action: PayloadAction<SessionCredential>) {
      state.session = action.payload;
    },
  }
});

export const {
  setSession,
} = authSlice.actions;

export default authSlice.reducer;