import properties from './props';

const SERVER_DOMAIN = properties.domain;

const API_VERSION = 'v1';

const LOGIN_ENDPOINT = {
  AUTH: `/auth/realms/${properties.realm}/protocol/openid-connect/token`,
};

const CLIENT_ENDPOINT = {
  CLIENTS: `${SERVER_DOMAIN}/api/${API_VERSION}/trusted-sync/clients`,
  SCHEDULE_CLIENTS: `${SERVER_DOMAIN}/api/${API_VERSION}/trusted-sync/clients/schedule`,
  UNSCHEDULE_CLIENTS: `${SERVER_DOMAIN}/api/${API_VERSION}/trusted-sync/clients/unschedule`,
};

const DATA_MAP_ENDPOINT = {
  DATA_MAPS: `${SERVER_DOMAIN}/api/${API_VERSION}/trusted-sync/data-maps`,
  DATA_MAPS_SOURCE: `${SERVER_DOMAIN}/api/${API_VERSION}/trusted-sync/data-maps/source`,
};

const COMPONENT_MAP_ENDPOINT = {
  COMPONENT_MAP: `${SERVER_DOMAIN}/api/${API_VERSION}/trusted-sync/component-maps`
}

const USER_ENDPOINT = {
  USERS: `${SERVER_DOMAIN}/api/${API_VERSION}/trusted-sync/users`,
};

const CLIENT_ID = properties.clientId;
const CLIENT_SECRET = properties.clientSecret;
const AUTH_GRANT_TYPE = "password";

export {
  CLIENT_ID,
  CLIENT_SECRET,
  AUTH_GRANT_TYPE,
  LOGIN_ENDPOINT,
  CLIENT_ENDPOINT,
  DATA_MAP_ENDPOINT,
  USER_ENDPOINT,
  SERVER_DOMAIN,
  COMPONENT_MAP_ENDPOINT,
}