import { useEffect } from "react";
import { Form, Input, Modal, Select, Space } from "antd";

import { User } from "../../model/user-model";

import _isEqual from "lodash/isEqual";

const userTypes = [
  { value: 'ADMIN', label: 'Administrator' },
  { value: 'STAFF', label: 'Staff' },
]

const requiredRule = (message: string) => {
  return {
    required: true,
    message: message,
  }
}

/**
 * 
 * @param param0 
 * @returns 
 */
const UserForm = ({ title, open, user, addUser, updateUser, onClose }: any) => {

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(user);
  }, user);

  const onFinish = (user: User) => {
  if (addUser) addUser(user);
    if (updateUser) updateUser(user);
  }

  return <Modal
    title={title}
    closable={false}
    open={open}
    width={400}
    onOk={() => form.submit()}
    onCancel={onClose}
  >
    <Form
      className="users-page-form"
      form={form}
      onFinish={onFinish}
    >
      <Space direction="vertical"  style={{ display: 'flex', marginTop: '2rem' }}>
        <Form.Item
          name="id"
          style={{display: 'none'}}
        >
          <Input />
        </Form.Item>
        
        <label>Email</label>
        <Form.Item
          name="email"
          rules={[requiredRule("Email is required.")]}
        >
          <Input />
        </Form.Item>
        {addUser && <><label>Password</label>
        <Form.Item
          name="password"
          rules={[requiredRule("Password is required.")]}
        >
          <Input.Password />
        </Form.Item></>}
        <label>First Name</label>
        <Form.Item
          name="firstName"
          rules={[requiredRule("First name is required.")]}
        >
          <Input />
        </Form.Item>
        <label>Last Name</label>
        <Form.Item
          name="lastName"
          rules={[requiredRule("Last name is required.")]}
        >
          <Input />
        </Form.Item>
        <label>User Type</label>
        <Form.Item
          name="userType"
          rules={[
            requiredRule("User Type is required.")
          ]}
        >
          <Select options={userTypes} />
        </Form.Item>
      </Space>

    </Form>
  </Modal>;
}

export { UserForm };



