import { SessionCredential } from "../../model/auth-model";
import { CONSTANT } from "../../util/constant";

export const getSessionCredentialsInStorage = () => {
  return () => {
    const session: any = localStorage.getItem(CONSTANT.SESSION_CREDENTIALS);
    return JSON.parse(session);
  };
};

export const setSessionCredentialsToStorage = (session: SessionCredential) => {
  return () => {
    let stringCredentials = JSON.stringify(session);
    localStorage.setItem(CONSTANT.SESSION_CREDENTIALS, stringCredentials);
  };
};

export const removeSessionCredentialsToStorage = () => {
  return () => {
    localStorage.removeItem(CONSTANT.SESSION_CREDENTIALS);
  };
};




