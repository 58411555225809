import React from 'react';
const DashboardPage = () => {
  return (
    <>
      <h1>Dashboard</h1>
      
    </>
  );
};
export default DashboardPage;

