import React from 'react';
import { Flex, Typography, Input } from 'antd';
import { ComponentProps } from '../model';

const { Text } = Typography;

interface Props {
  component: ComponentProps;
  onChangeField: (cP: ComponentProps, field: string, value: any) => void;
}
const PropertyFieldLabel: React.FC<Props> = (props): JSX.Element => {

  if (props.component.type === 'Text' ||
    props.component.type === 'Checkbox' 
  ) {
    return (
      <Flex
        vertical={true}
      >
        <Text
          type="secondary"
          style={{
            color: '#231F20',
            fontSize: '14px',
            fontWeight: '500',
            WebkitUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none'
          }}
        >
          Label
        </Text>
        <Input
          style={{ fontSize: "14px" }}
          value={props.component.label}
          onChange={(e: any) => props.onChangeField(props.component, "label", e.target.value)}
          placeholder='Please input label'
        />
      </Flex>
    )
  }

  return <></>
}

export default PropertyFieldLabel;