import { useEffect, useState } from "react";
import { Col, Form, Input, Row, Select, Space, Steps } from "antd";

import { Client } from "../../model/client-model";

import _isEqual from "lodash/isEqual";

const grantTypes = [
  { label: 'Client Credentials', value: 'CLIENT_CREDENTIALS' },
  { label: 'Password', value: 'PASSWORD' },
]

const payloadTypes = [
  { label: 'CSV', value: 'CSV' },
  { label: 'Excel', value: 'EXCEL' },
  { label: 'JSON', value: 'JSON' },
  { label: 'XML', value: 'XML' },
  { label: 'PDF', value: 'PDF' },
  { label: 'Word', value: 'WORD' },
]

const channelTypes = [
  { label: 'Secure FTP', value: 'sFTP' },
  { label: 'SOAP', value: 'SOAP' },
  { label: 'REST', value: 'REST' },
  { label: 'Apache Kafka', value: 'KAFKA' },
  { label: 'MQTT', value: 'MQTT' },
  { label: 'TCP / IP', value: 'TCP_IP' },
]

const RESTMethods = [
  { label: 'PUT', value: 'PUT' },
  { label: 'POST', value: 'POST' }
]

const steps = [
  { title: 'Client' },
  { title: 'Source' },
  { title: 'Target' },
];

const requiredRule = (message: string) => {
  return {
    required: true,
    message: message,
  }
}

const ClientConfiguration = ({show, onChannelTypeChange}: any) => {

  return <div style={{overflow: 'hidden', height: show ? 'auto' : '0rem'}}>

    <h2>Client and Service Configuration</h2>
    
    <h3>General Settings</h3>
    <label>Name</label>
    <Form.Item
      name="name"
      rules={[
        requiredRule("Name is required.")
      ]}
    >
      <Input />
    </Form.Item>

    <h3>Service Settings</h3>
      <label>Payload Type</label>
      <Form.Item
        name="payloadType"
        rules={[
          requiredRule("Payload Type is required.")
        ]}
      >
        <Select options={payloadTypes} />
      </Form.Item>
      <label>Channel Type</label>
      <Form.Item
        name="channelType"
        rules={[
          requiredRule("Channel Type is required.")
        ]}
      >
        <Select options={channelTypes} onChange={onChannelTypeChange} />
      </Form.Item>
    
    <h3>Scheduling</h3>
    <label>Cron Schedule</label>
    <Form.Item
      name="cronSchedule"
      rules={[
        requiredRule("Schedule is required.")
      ]}
    >
    <Input />
  </Form.Item>
  </div>
}

const SourceConfiguration = ({show, sourceGrantType}: any) => {

  const [grantType, setGrantType] = useState<string>();

  useEffect(() => {
    setGrantType(sourceGrantType);
  }, [sourceGrantType]);

  return <div style={{overflow: 'hidden', height: show ? 'auto' : '0rem'}}>

    <h2>Source Configuration</h2>
    <h3>Source</h3>
    <label>Data Endpoint</label>
    <Form.Item
      name="tdSourceURL"
      rules={[
        requiredRule("Source URL is required.")
      ]}
    >
      <Input />
    </Form.Item>

    <h3>OAuth 2.0</h3>
    <label>Authentication Endpoint</label>
    <Form.Item
      name="tdAuthURL"
      rules={[
        requiredRule("Authentication URL is required.")
      ]}
    >
      <Input />
    </Form.Item>
    <label>Grant Type</label>
    <Form.Item
      name="tdGrantType"
      rules={[
        requiredRule("Grant Type is required.")
      ]}
    >
      <Select options={grantTypes} onChange={setGrantType} />
    </Form.Item>
    <label>Client ID</label>
    <Form.Item
      name="tdClientId"
      rules={[
        requiredRule("Client ID is required.")
      ]}
    >
      <Input />
    </Form.Item>

    {'PASSWORD' === grantType 
    && <>
      <h3>User Credentials</h3>
      <Row gutter={16}>
        <Col span={12}>
          <label>Username</label>
          <Form.Item
            name="tdUsername"
            // rules={[
            //   requiredRule("Username is required.")
            // ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label>Password</label>
          <Form.Item
            name="tdPassword"
            // rules={[
            //   requiredRule("Password is required.")
            // ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
    </>
    || <>
      <label>Client Secret</label>
      <Form.Item
        name="tdClientSecret"
        // rules={[
        //   requiredRule("Client Secret is required.")
        // ]}
      >
        <Input.Password />
      </Form.Item>
    </>}
  </div>
}

const SFTPConfiguration = ({show}: any) => {

  return <div style={{overflow: 'hidden', height: show ? 'auto' : '0rem'}}>

    <h2>Secure FTP Configuration</h2>
    
    <h3>Network Configuration</h3>
    <Row gutter={16}>
      <Col span={12}>
        <label>Protocol</label>
        <Form.Item
          name="targetProtocol"
          // rules={[
          //   requiredRule("Protocol is required.")
          // ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <label>Host</label>
        <Form.Item
          name="targetHost"
          // rules={[
          //   requiredRule("Host is required.")
          // ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={12}>
        <label>Username</label>
        <Form.Item
          name={['sftpChannel', 'targetUsername']}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <label>Password</label>
        <Form.Item
          name={['sftpChannel', 'targetPassword']}
        >
          <Input.Password />
        </Form.Item>
      </Col>
    </Row>
    <h3>File and Directory Management</h3>
    <label>Target Directory</label>
    <Form.Item
      name={['sftpChannel', 'targetDirectoryName']}
      // rules={[
      //   requiredRule("Directory is required.")
      // ]}
    >
      <Input />
    </Form.Item>
    <label>File Name</label>
    <Form.Item
      name={['sftpChannel', 'fileName']}
      // rules={[
      //   requiredRule("Filename is required.")
      // ]}
    >
      <Input />
    </Form.Item>
  </div>
}

const RESTConfiguration = ({show}: any) => {

  const [targetGrantType, setTargetGrantType] = useState<string>();

  return <div style={{overflow: 'hidden', height: show ? 'auto' : '0rem'}}>

    <h2>RESTful Configuration</h2>
    <h3>Network Configuration</h3>
    <Row gutter={16}>
      <Col span={6}>
        <label>Protocol</label>
        <Form.Item
          name="targetProtocol"
          // rules={[
          //   requiredRule("Protocol is required.")
          // ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={18}>
        <label>Host</label>
        <Form.Item
          name="targetHost"
          // rules={[
          //   requiredRule("Host is required.")
          // ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={6}>
        <label>Request Method</label>
        <Form.Item
          name={['restChannel', 'requestMethod']}
          // rules={[
          //   requiredRule("Method is required.")
          // ]}
        >
          <Select options={RESTMethods} />
        </Form.Item>
      </Col>
      <Col span={18}>
        <label>Target Endpoint</label>
        <Form.Item
          name={['restChannel', 'targetAuthEndpoint']}
          // rules={[
          //   requiredRule("Target Endpoint is required.")
          // ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>

    <h3>OAuth 2.0</h3>
    <label>Grant Type</label>
    <Form.Item
      name={['restChannel', 'targetGrantType']}
      // rules={[
      //   requiredRule("Grant Type is required.")
      // ]}
    >
      <Select options={grantTypes} onChange={setTargetGrantType} />
    </Form.Item>
      <label>Client ID</label>
      <Form.Item
        name={['restChannel', 'targetClientId']}
        // rules={[
        //   requiredRule("Client ID is required.")
        // ]}
      >
        <Input />
      </Form.Item>
      <label>Client Secret</label>
      <Form.Item
        name={['restChannel', 'targetClientSecret']}
      // rules={[
      //   requiredRule("Client Secret is required.")
      // ]}
      >
        <Input.Password />
      </Form.Item>
    
    {('PASSWORD' === targetGrantType) &&
    <>
      <h3>User Credentials</h3>
      <Row gutter={16}>
        <Col span={12}>
          <label>Username</label>
          <Form.Item
            name={['restChannel', 'targetUsername']}
            // rules={[
            //   requiredRule("Username is required.")
            // ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label>Password</label>
          <Form.Item
            name={['restChannel', 'targetPassword']}
            // rules={[
            //   requiredRule("Password is required.")
            // ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
    </>}

    {/* {!_isEqual(form.getFieldValue('channelType'), 'sFTP') &&
      !_isEqual(form.getFieldValue('channelType'), 'REST') &&
      (<label>Context Path</label>)}
    {!_isEqual(form.getFieldValue('channelType'), 'sFTP') &&
      !_isEqual(form.getFieldValue('channelType'), 'REST') && (
        <Form.Item
          name="targetContextPath"
        >
          <Input />
        </Form.Item>
      )} */}
  </div>
}

const SOAPConfiguration = ({show}: any) => {

  const [targetGrantType, setTargetGrantType] = useState<string>();

  return <div style={{overflow: 'hidden', height: show ? 'auto' : '0rem'}}>

    <h2>SOAP Configuration</h2>
    <h3>Network Configuration</h3>
    <Row gutter={16}>
      <Col span={6}>
        <label>Protocol</label>
        <Form.Item
          name="targetProtocol"
          // rules={[
          //   requiredRule("Protocol is required.")
          // ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={18}>
        <label>Host</label>
        <Form.Item
          name="targetHost"
          // rules={[
          //   requiredRule("Host is required.")
          // ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
    <label>Endpoint</label>
    <Form.Item
      name={['soapChannel', 'targetEndpoint']}
      // rules={[
      //   requiredRule("Target Endpoint is required.")
      // ]}
    >
      <Input />
    </Form.Item>
    <label>Namespace URI</label>
    <Form.Item
      name={['soapChannel', 'targetNamespaceUri']}
      // rules={[
      //   requiredRule("Namespace URI is required.")
      // ]}
    >
      <Input />
    </Form.Item>
  </div>
}

/**
 * 
 * @param param0 
 * @returns 
 */
const ClientForm = ({ form, channel, tdGrantType, state, addClient, updateClient }: any) => {

  const [current, setCurrent] = useState<number>(1);
  const [channelType, setChannelType] = useState<string>();

  useEffect(() => {
    setChannelType(channel);
  }, [channel]);

  const onChannelTypeChange = (channelType: string): void => {
    setChannelType(channelType);
  }

  const onFinish = (client: Client) => {

    console.log('updateClient', updateClient);
    if (addClient) addClient(client);
    if (updateClient) updateClient(client);
  }

  return <Form
    className="clients-page-form"
    form={form}
    onFinish={onFinish}
  >
   <Space direction="vertical"  style={{ display: 'flex', marginTop: '2rem' }}>
    
    <Steps current={state} items={steps} />
      <ClientConfiguration show={state === 0} onChannelTypeChange={onChannelTypeChange}/>
      <SourceConfiguration show={state === 1} sourceGrantType={tdGrantType}/>
      <SFTPConfiguration show={state === 2 && channelType === 'sFTP'}/>
      <RESTConfiguration show={state === 2 && channelType === 'REST'}/>
      <SOAPConfiguration show={state === 2 && channelType === 'SOAP'}/>
    </Space>

  </Form>;
}

export { ClientForm };



