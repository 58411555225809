import './data-mapping-page.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Dropdown, Input, notification, Select, Space, Spin, Tree } from "antd";
import { AiOutlineDelete, AiOutlineDown, AiOutlineEdit } from "react-icons/ai";
import { FaHashnode, FaPlus, FaSquare } from "react-icons/fa6";
import { MdOutlineDataArray } from "react-icons/md";
import { RiSwapBoxFill } from "react-icons/ri";
import { RxText } from "react-icons/rx";

import { Client } from '../../model/client-model';
import { TdiNodeModel } from '../../model/tdi-node-model';
import { DataMapModel } from '../../model/data-map-model';

import { useGetDataMapMutation, useGetDataFieldsMutation, useSaveDataMapMutation } from '../../redux/api/data-maps';
import { useGetClientMutation } from '../../redux/api/clients';
import { ConverterModal } from './converter-modal';

interface Props { }

const DataMappingPage: React.FC<Props> = (): JSX.Element => {

    const [loading, setLoading] = useState<boolean>();
    const [dataMapFields, setDataMapFields] = useState([]);
    const [treeData, setTreeData] = useState<TdiNodeModel[]>([]);
    const [currentNodeModel, setCurrentNodeModel] = useState<TdiNodeModel>();
    const [client, setClient] = useState<Client>();
    const [edited, setEdited] = useState<boolean>(false);
    const [isConverterOpen, setConverterOpen] = useState<boolean>(false);

    const nodeItems = [
        {
            key: 'NODE',
            icon: <FaPlus />,
            label: 'Add Child Node',
            children: [
                {
                    key: 'ADD_CHILD_NODE',
                    icon: <FaHashnode />,
                    label: 'Add Node'
                },
                {
                    key: 'ADD_CHILD_ARRAY_NODE',
                    icon: <MdOutlineDataArray />,
                    label: 'Add Array Node'
                },
                {
                    key: 'ADD_CHILD_STATIC_TEXT',
                    icon: <RxText />,
                    label: 'Add Static Text Node'
                },
                {
                    key: 'ADD_CHILD_MAPPED_TEXT',
                    icon: <RiSwapBoxFill />,
                    label: 'Add Mapped Node'
                }
            ]
        },
        {
            key: 'ABOVE_NODE',
            icon: <FaPlus />,
            label: 'Add Sibling Node Above',
            children: [
                {
                    key: 'ADD_SIBILING_NODE_ABOVE',
                    icon: <FaHashnode />,
                    label: 'Add Node'
                },
                {
                    key: 'ADD_SIBILING_ARRAY_NODE_ABOVE',
                    icon: <MdOutlineDataArray />,
                    label: 'Add Array Node'
                },
                {
                    key: 'ADD_SIBILING_STATIC_TEXT_ABOVE',
                    icon: <RxText />,
                    label: 'Add Static Text Node'
                },
                {
                    key: 'ADD_SIBILING_MAPPED_TEXT_ABOVE',
                    icon: <RiSwapBoxFill />,
                    label: 'Add Mapped Node'
                }
            ]
        },
        {
            key: 'BELOW_NODE',
            icon: <FaPlus />,
            label: 'Add Sibling Node Below',
            children: [
                {
                    key: 'ADD_SIBILING_NODE_BELOW',
                    icon: <FaHashnode />,
                    label: 'Add Node'
                },
                {
                    key: 'ADD_SIBILING_ARRAY_NODE_BELOW',
                    icon: <MdOutlineDataArray />,
                    label: 'Add Array Node'
                },
                {
                    key: 'ADD_SIBILING_STATIC_TEXT_BELOW',
                    icon: <RxText />,
                    label: 'Add Static Text Node'
                },
                {
                    key: 'ADD_SIBILING_MAPPED_TEXT_BELOW',
                    icon: <RiSwapBoxFill />,
                    label: 'Add Mapped Node'
                }
            ]
        },
        {
            key: 'ADD_ATTRIBUTE',
            icon: <FaSquare />,
            label: 'Add Attribute'
        },
        {
            key: 'CHANGE_NODE_TYPE',
            icon: <AiOutlineEdit />,
            label: 'Change Node Type',
            children: [
                {
                    key: 'CHANGE_TO_NODE',
                    icon: <RiSwapBoxFill />,
                    label: 'Change to Node'
                },
                {
                    key: 'CHANGE_TO_ARRAY_NODE',
                    icon: <RiSwapBoxFill />,
                    label: 'Change to Array Node'
                },
                {
                    key: 'CHANGE_TO_STATIC_TEXT',
                    icon: <RiSwapBoxFill />,
                    label: 'Change to Static Text Node'
                },
                {
                    key: 'CHANGE_TO_MAPPED_TEXT',
                    icon: <RiSwapBoxFill />,
                    label: 'Change to Mapped Text Node'
                }
            ]
        },
        {
            key: 'DELETE_NODE',
            icon: <AiOutlineDelete />,
            label: 'Delete Node'
        },
    ]

    const textNodeItems = [
        {
            key: 'ABOVE_NODE',
            icon: <FaPlus />,
            label: 'Add Sibling Node Above',
            children: [
                {
                    key: 'ADD_SIBILING_NODE_ABOVE',
                    icon: <FaHashnode />,
                    label: 'Add Node'
                },
                {
                    key: 'ADD_SIBILING_ARRAY_NODE_ABOVE',
                    icon: <MdOutlineDataArray />,
                    label: 'Add Array Node'
                },
                {
                    key: 'ADD_SIBILING_STATIC_TEXT_ABOVE',
                    icon: <RxText />,
                    label: 'Add Static Text Node'
                },
                {
                    key: 'ADD_SIBILING_MAPPED_TEXT_ABOVE',
                    icon: <RiSwapBoxFill />,
                    label: 'Add Mapped Node'
                }
            ]
        },
        {
            key: 'BELOW_NODE',
            icon: <FaPlus />,
            label: 'Add Sibling Node Below',
            children: [
                {
                    key: 'ADD_SIBILING_NODE_BELOW',
                    icon: <FaHashnode />,
                    label: 'Add Node'
                },
                {
                    key: 'ADD_SIBILING_ARRAY_NODE_BELOW',
                    icon: <MdOutlineDataArray />,
                    label: 'Add Array Node'
                },
                {
                    key: 'ADD_SIBILING_STATIC_TEXT_BELOW',
                    icon: <RxText />,
                    label: 'Add Static Text Node'
                },
                {
                    key: 'ADD_SIBILING_MAPPED_TEXT_BELOW',
                    icon: <RiSwapBoxFill />,
                    label: 'Add Mapped Node'
                }
            ]
        },
        {
            key: 'ADD_ATTRIBUTE',
            icon: <FaSquare />,
            label: 'Add Attribute'
        },
        {
            key: 'CHANGE_NODE_TYPE',
            icon: <AiOutlineEdit />,
            label: 'Change Node Type',
            children: [
                {
                    key: 'CHANGE_TO_NODE',
                    icon: <RiSwapBoxFill />,
                    label: 'Change to Node'
                },
                {
                    key: 'CHANGE_TO_ARRAY_NODE',
                    icon: <RiSwapBoxFill />,
                    label: 'Change to Array Node'
                },
                {
                    key: 'CHANGE_TO_STATIC_TEXT',
                    icon: <RiSwapBoxFill />,
                    label: 'Change to Static Text Node'
                },
                {
                    key: 'CHANGE_TO_MAPPED_TEXT',
                    icon: <RiSwapBoxFill />,
                    label: 'Change to Mapped Text Node'
                }
            ]
        },
        {
            key: 'DELETE_NODE',
            icon: <AiOutlineDelete />,
            label: 'Delete Node'
        },
    ]

    const attributeItems = [
        {
            key: 'CHANGE_NODE_TYPE',
            icon: <AiOutlineEdit />,
            label: 'Change Node Type',
            children: [
                {
                    key: 'CHANGE_TO_NODE',
                    icon: <RiSwapBoxFill />,
                    label: 'Change to Node'
                },
                {
                    key: 'CHANGE_TO_ARRAY_NODE',
                    icon: <RiSwapBoxFill />,
                    label: 'Change to Array Node'
                },
                {
                    key: 'CHANGE_TO_STATIC_TEXT',
                    icon: <RiSwapBoxFill />,
                    label: 'Change to Static Text Node'
                },
                {
                    key: 'CHANGE_TO_MAPPED_TEXT',
                    icon: <RiSwapBoxFill />,
                    label: 'Change to Mapped Text Node'
                }
            ]
        },
        {
            key: 'DELETE_NODE',
            icon: <AiOutlineDelete />,
            label: 'Delete Node'
        },
    ]

    const { clientId } = useParams();

    const [getClient] = useGetClientMutation();
    const [getDataMapFields] = useGetDataFieldsMutation();
    const [getDataMap] = useGetDataMapMutation();
    const [saveDataMap] = useSaveDataMapMutation();

    useEffect(() => {

        const fetchDataMapFields = async () => {
            try {
                setLoading(true);
                const data = await getDataMapFields(clientId).unwrap();
                setDataMapFields(data.map((field: string) => {
                    return { value: field, label: field };
                }));
                setLoading(false);
            } catch (e) {
                console.log(e);
                notification.error({
                    message: "Data Fields Error!",
                    description: `An error occurred while fetching the trusted driver data fields.`
                });
            }
        }

        const fetchClient = async () => {
            try {
                const data = await getClient(clientId).unwrap();
                setClient(data);
            } catch (e) {
                console.log(e);
                notification.error({
                    message: "Clients Error!",
                    description: `An error occurred while fetching the clients.`
                });
            }
        }

        fetchDataMapFields();
        fetchClient();

    }, []);

    useEffect(() => {
        
        const fetchDataMaps = async () => {

            const initializeTree = (nodes?: DataMapModel[]): TdiNodeModel[] => {

                const children = nodes?.map((item: DataMapModel) => {
                    let dataMap = null;
                    switch (item?.type) {
                        case 'ATTRIBUTE': dataMap = createAttribute(undefined, item); break;
                        case 'MAPPED_TEXT': dataMap = createMappedTextNode(undefined, item); break;
                        case 'STATIC_TEXT': dataMap = createStaticTextNode(undefined, item); break;
                        case 'ARRAY_NODE': dataMap = createArrayNode(undefined, item); break;
                        default: dataMap = createNode(undefined, item)
                    }
                    dataMap.children = item.children ? initializeTree(item.children) : [];
                    return dataMap;
                });
                return children ? children : [];
            }

            try {
                const dataMap = await getDataMap(clientId).unwrap();
                if (dataMap && dataMap.children && dataMap.children.length > 0) {
                    const data = initializeTree(dataMap.children);
                    setTreeData(data);
                }
            } catch (e) {
                console.log(e);
                notification.error({
                    message: "Data Map Configuration Error!",
                    description: `An error occurred while fetching the data map configuration.`
                });
            }
        }

        if (dataMapFields.length != 0) {
            fetchDataMaps();
        }

    }, [dataMapFields])

    /**
     * Recursive function to traverse the tree node and find the node equivalent to
     * the node.
     * 
     * @param node the node find
     * @param nodes the tree nodes
     * @returns 
     */
    //@ts-ignore
    const findNodeFromTree = (node: TdiNodeModel, nodes: TdiNodeModel[]): TdiNodeModel => {
        for (let i = 0; i < nodes.length; i++) {
            let item = nodes[i];
            if (item.key.toString() === node.key.toString()) {
                return item;
            }
            if (item.children) {
                let foundItem = findNodeFromTree(node, item.children);
                if (foundItem) {
                    return foundItem;
                }
            }
        }
    }

    /**
     * Recursive function to traverse the tree node and find the parent node given the node's
     * parent key.
     */

    //@ts-ignore
    const findParentFromTree = (parentKey?: string, nodes: TdiNodeModel[]): TdiNodeModel => {
        for (let i = 0; i < nodes.length; i++) {
            let item = nodes[i];
            if (item.key.toString() === parentKey) {
                return item;
            }
            if (item.children) {
                let foundItem = findParentFromTree(parentKey, item.children);
                if (foundItem) {
                    return foundItem;
                }
            }
        }
    }

    /**
     * Adds a child node to the parent node
     * @param node the parent node
     * @param childNode the node to add
     */
    const addToParentNode = (parentNode: TdiNodeModel, childNode: TdiNodeModel) => {

        setTreeData((tree) => {
            let clonedTree = [...tree];
            let children = parentNode.children ? [...parentNode.children, childNode] : [childNode];
            let foundNode: TdiNodeModel = findNodeFromTree(parentNode, clonedTree);
            if (foundNode) {
                foundNode.children = children;
            }
            return clonedTree;
        });
    }

    /**
     * Add a node above the selected node.
     * 
     * @param selectedNode the selected node
     * @param siblingNode the sibling node to add above
     */
    const addNodeAbove = (selectedNode: TdiNodeModel, siblingNode: TdiNodeModel) => {

        setTreeData((tree) => {
            let clonedTree = [...tree];
            let parentNode: TdiNodeModel = findParentFromTree(selectedNode.parentKey, clonedTree);
            if (parentNode) {
                let children = [...parentNode.children];
                const ndx = children.findIndex((child) => {
                    return child.key.toString() === selectedNode.key.toString()
                });
                children.splice(ndx, 0, siblingNode);
                parentNode.children = children;
                return clonedTree;
            } else {
                let clonedTree = [...tree];
                const ndx = clonedTree.findIndex((child) => child.key.toString() === selectedNode.key.toString());
                clonedTree.splice(ndx, 0, siblingNode);
                return clonedTree;
            }
        });
    }

    /**
     * Add a node below the selected node.
     * 
     * @param selectedNode the selected node
     * @param siblingNode the sibling node to add below
     */
    const addNodeBelow = (selectedNode: TdiNodeModel, siblingNode: TdiNodeModel) => {

        setTreeData((tree) => {
            let clonedTree = [...tree];
            let parentNode: TdiNodeModel = findParentFromTree(selectedNode.parentKey, clonedTree);
            if (parentNode) {
                let children = [...parentNode.children];
                const ndx = children.findIndex((child) => {
                    return child.key.toString() === selectedNode.key.toString()
                });
                if (ndx !== children.length -1) {
                    children.splice(ndx + 1, 0, siblingNode);
                } else {
                    children.push(siblingNode);
                }
                parentNode.children = children;
                return clonedTree;
            } else {
                let clonedTree = [...tree];
                const ndx = clonedTree.findIndex((child) => child.key.toString() === selectedNode.key.toString());
                if (ndx !== clonedTree.length - 1) {
                    clonedTree.splice(ndx + 1, 0, siblingNode);
                } else {
                    clonedTree.push(siblingNode);
                }
                return clonedTree;
            }
        });
    }

    /**
     * Handles a node action whether to add a node, add a text node, or to delete a node.
     * 
     * @param menuKey the identifier of the menu item that received a click.
     * @param node the node associated with the action.
     */
    const handleNodeAction = (menuKey: string, node: TdiNodeModel) => {

        switch (menuKey) {

            case 'ADD_CHILD_NODE':
                const childNode = createNode(node.key.toString());
                addToParentNode(node, childNode);
                break;

            case 'ADD_CHILD_ARRAY_NODE':
                const childArrayNode = createArrayNode(node.key.toString());
                addToParentNode(node, childArrayNode);
                break;

            case 'ADD_CHILD_MAPPED_TEXT':
                const childMappedTextNode = createMappedTextNode(node.key.toString());
                addToParentNode(node, childMappedTextNode);
                break;

            case 'ADD_CHILD_STATIC_TEXT':
                const childStaticTextNode = createStaticTextNode(node.key.toString());
                addToParentNode(node, childStaticTextNode);
                break;

            case 'ADD_ATTRIBUTE':
                const childAttribute = createAttribute(node.key.toString());
                addToParentNode(node, childAttribute);
                break;

            case 'ADD_SIBILING_NODE_ABOVE':
                const siblingNodeAbove = createNode(node.parentKey?.toString());
                addNodeAbove(node, siblingNodeAbove);
                break;

            case 'ADD_SIBILING_ARRAY_NODE_ABOVE':
                const siblingArrayNodeAbove = createArrayNode(node.parentKey?.toString());
                addNodeAbove(node, siblingArrayNodeAbove);
                break;

            case 'ADD_SIBILING_STATIC_TEXT_ABOVE':
                const siblingStaticTextAbove = createStaticTextNode(node.parentKey?.toString());
                addNodeAbove(node, siblingStaticTextAbove);
                break;
            
            case 'ADD_SIBILING_MAPPED_TEXT_ABOVE':
                const siblingMappedTextAbove = createMappedTextNode(node.parentKey?.toString());
                addNodeAbove(node, siblingMappedTextAbove);
                break;

            case 'ADD_SIBILING_NODE_BELOW':
                const siblingNodeBelow = createNode(node.parentKey?.toString());
                addNodeBelow(node, siblingNodeBelow);
                break;

            case 'ADD_SIBILING_ARRAY_NODE_BELOW':
                const siblingArrayNodeBelow = createArrayNode(node.parentKey?.toString());
                addNodeBelow(node, siblingArrayNodeBelow);
                break;

            case 'ADD_SIBILING_STATIC_TEXT_BELOW':
                const siblingStaticTextBelow = createStaticTextNode(node.parentKey?.toString());
                addNodeBelow(node, siblingStaticTextBelow);
                break;
            
            case 'ADD_SIBILING_MAPPED_TEXT_BELOW':
                const siblingMappedTextBelow = createMappedTextNode(node.parentKey?.toString());
                addNodeBelow(node, siblingMappedTextBelow);
                break;

            case 'CHANGE_TO_NODE': 
                changeNodeType(node, 'NODE');
                break;

            case 'CHANGE_TO_ARRAY_NODE': 
                changeNodeType(node, 'ARRAY_NODE');
                break;

            case 'CHANGE_TO_STATIC_TEXT': 
                changeNodeType(node, 'STATIC_TEXT');
                break;

            case 'CHANGE_TO_MAPPED_TEXT': 
                changeNodeType(node, 'MAPPED_TEXT');
                break;

            case 'DELETE_NODE':
                setTreeData((tree) => {
                    let clonedTree = [...tree];
                    let parentNode: TdiNodeModel = findParentFromTree(node.parentKey, clonedTree);
                    if (parentNode) {
                        let children = [...parentNode.children];
                        const ndx = children.findIndex((child) => child.key.toString() === node.key.toString());
                        children.splice(ndx, 1);
                        parentNode.children = children;
                        return clonedTree;
                    } else {
                        let clonedTree = [...tree];
                        const ndx = clonedTree.findIndex((child) => child.key.toString() === node.key.toString());
                        clonedTree.splice(ndx, 1);
                        return clonedTree;
                    }
                });
                break;
        }

        if (!edited) setEdited(true);
    }

    const nodeDropDown = (node: TdiNodeModel) => {

        return <Dropdown
            trigger={['contextMenu']}
            menu={{
                items: nodeItems,
                onClick: ({ key }) => handleNodeAction(key, node)
            }}>
            <div style={{ cursor: 'pointer' }} onClick={(e) => e.preventDefault()}>
                <Space>
                    <div className="field-row">
                        <FaHashnode size={18} />
                        <Input defaultValue={node.target} className="input-item"
                            onChange={(e) => {
                                node.target = e.target.value;
                                if (!edited) setEdited(true);
                            }} />
                        <div />
                    </div>
                </Space>
            </div>
        </Dropdown>
    }

    const arrayNodeDropDown = (node: TdiNodeModel) => {
        return <Dropdown
            trigger={['contextMenu']}
            menu={{
                items: nodeItems,
                onClick: ({ key }) => handleNodeAction(key, node)
            }}>
            <div style={{ cursor: 'pointer' }} onClick={(e) => e.preventDefault()}>
                <Space>
                    <div className="field-row">
                        <MdOutlineDataArray size={18} />
                        <Input defaultValue={node.target} className="input-item"
                            onChange={(e) => {
                                node.target = e.target.value;
                                if (!edited) setEdited(true);
                            }} />
                        <Select defaultValue={node.source} className="select-item"
                            options={dataMapFields}
                            onChange={(text) => {
                                node.source = text;
                                if (!edited) setEdited(true);
                            }} />
                        <div />
                    </div>
                </Space>
            </div>
        </Dropdown>;
    }

    const staticTextDropDown = (node: TdiNodeModel) => {
        return <Dropdown
            trigger={['contextMenu']}
            menu={{
                items: textNodeItems,
                onClick: ({ key }) => handleNodeAction(key, node)
            }}>
            <div style={{ cursor: 'pointer' }} onClick={(e) => e.preventDefault()}>
                <Space>
                    <div className="field-row">
                        <RxText size={18} />
                        <Input defaultValue={node.target} className="input-item"
                            onChange={(e) => {
                                node.target = e.target.value;
                                if (!edited) setEdited(true);
                            }} />
                        <Input defaultValue={node.source} className="select-item"
                            onChange={(e) => {
                                node.source = e.target.value;
                                if (!edited) setEdited(true);
                            }} />
                    </div>
                </Space>
            </div>
        </Dropdown>;
    }

    const mappedTextDropDown = (node: TdiNodeModel) => {
        return <Dropdown
            trigger={['contextMenu']}
            menu={{
                items: textNodeItems,
                onClick: ({ key }) => handleNodeAction(key, node)
            }}>
            <div style={{ cursor: 'pointer' }} onClick={(e) => e.preventDefault()}>
                <Space>
                    <div className="field-map-row">
                        <RiSwapBoxFill size={18} />
                        <Input defaultValue={node.target} className="input-item"
                            onChange={(e) => {
                                node.target = e.target.value;
                                if (!edited) setEdited(true);
                            }} />
                        <Select defaultValue={node.source} className="select-item"
                            options={dataMapFields}
                            onChange={(text) => {
                                node.source = text;
                                if (!edited) setEdited(true);
                            }} />
                        <Button type="primary" onClick={() => openConverter(node)}>
                            Converter
                        </Button>
                        {node.required && <span style={{color: 'red'}}>required</span>}
                    </div>
                </Space>
            </div>
        </Dropdown>
    }

    /**
     * Creates a Node.
     * 
     * @param parentKey the key of the parent to add the node to
     * @param dataMapModel the model from the database to map to a node, if existing.
     * @returns the node model
     */
    const createNode = (parentKey?: string, dataMapModel?: DataMapModel): TdiNodeModel => {

        console.log('parentKey', parentKey);

        let childNode: TdiNodeModel = {
            parentKey: dataMapModel?.parentKey || parentKey,
            type: 'NODE',
            key: dataMapModel?.key || crypto.randomUUID(),
            source: dataMapModel?.source,
            target: dataMapModel?.target,
            required: true,
            children: [],
        }

        childNode.title = nodeDropDown(childNode);
        return childNode;
    }

       /**
     * Creates an Array Node.
     * 
     * @param parentKey the key of the parent to add the node to
     * @param dataMapModel the model from the database to map to a node, if existing.
     * @returns the node model
     */
    const createArrayNode = (parentKey?: string, dataMapModel?: DataMapModel): TdiNodeModel => {

        let node: TdiNodeModel = {
            parentKey: dataMapModel?.parentKey || parentKey,
            type: 'ARRAY_NODE',
            key: dataMapModel?.key || crypto.randomUUID(),
            source: dataMapModel?.source,
            target: dataMapModel?.target,
            required: true,
            children: [],
        }

        node.title = arrayNodeDropDown(node);
        return node;
    }
    
    const openConverter = (node:TdiNodeModel) => {
        setCurrentNodeModel(node);
        setConverterOpen(true);
    }

    /**
     * Creates a Mapped Text Node.
     * 
     * @param parentKey the key of the parent to add the text node to
     * @param dataMapModel the model from the database to map to a node, if existing.
     * @returns the text node model
     */
    const createMappedTextNode = (parentKey?: string, dataMapModel?: DataMapModel): TdiNodeModel => {

        let node: TdiNodeModel = {
            parentKey: dataMapModel?.parentKey || parentKey,
            type: 'MAPPED_TEXT',
            key: dataMapModel?.key || crypto.randomUUID(),
            source: dataMapModel?.source,
            target: dataMapModel?.target,
            required: dataMapModel?.required,
            children: [],
            converterType: dataMapModel?.converterType || 'NONE',
            script: dataMapModel?.script,
            enums: dataMapModel?.enums,
        }

        node.title = mappedTextDropDown(node);
        return node;
    }

    /**
     * Creates a Mapped Text Node.
     * 
     * @param parentKey the key of the parent to add the text node to
     * @param dataMapModel the model from the database to map to a node, if existing.
     * @returns the text node model
     */
    const createStaticTextNode = (parentKey?: string, dataMapModel?: DataMapModel): TdiNodeModel => {

        let node: TdiNodeModel = {
            parentKey: dataMapModel?.parentKey || parentKey,
            type: 'STATIC_TEXT',
            key: dataMapModel?.key || crypto.randomUUID(),
            source: dataMapModel?.source,
            target: dataMapModel?.target,
            required: true,
            children: [],
        }

        node.title = staticTextDropDown(node);
        return node;
    }

    /**
     * changes the node type to {type}.
     * 
     * @param parentKey the key of the parent to add the attribute to
     * @param dataMapModel the model from the database to map to a node, if existing.
     * @returns the text node model
     */
    const changeNodeType = (node: TdiNodeModel, nodeType: string): TdiNodeModel => {

        node.type = nodeType;

        switch (nodeType) {
            case 'NODE': node.title = nodeDropDown(node); break;
            case 'ARRAY_NODE': node.title = arrayNodeDropDown(node); break;
            case 'STATIC_TEXT': node.title = staticTextDropDown(node); break;
            case 'MAPPED_TEXT': node.title = mappedTextDropDown(node); break;
        }
        
        setTreeData((tree) => {
            let clonedTree = [...tree];
            let parentNode: TdiNodeModel = findParentFromTree(node.parentKey, clonedTree);
            if (parentNode) {
                let children = [...parentNode.children];
                const ndx = children.findIndex((child) => child.key.toString() === node.key.toString());
                children.splice(ndx, 1, node);
                parentNode.children = children;
                return clonedTree;
            } else {
                let clonedTree = [...tree];
                const ndx = clonedTree.findIndex((child) => child.key.toString() === node.key.toString());
                clonedTree.splice(ndx, 1, node);
                return clonedTree;
            }
        });
        
        return node;
    }

    /**
     * Creates an Attribute.
     * 
     * @param parentKey the key of the parent to add the attribute to
     * @param dataMapModel the model from the database to map to a node, if existing.
     * @returns the text node model
     */
    const createAttribute = (parentKey?: string, dataMapModel?: DataMapModel): TdiNodeModel => {

        let node: TdiNodeModel = {
            parentKey: dataMapModel?.parentKey || parentKey,
            type: 'ATTRIBUTE',
            key: dataMapModel?.key || crypto.randomUUID(),
            source: dataMapModel?.source,
            target: dataMapModel?.target,
            required: false,
            children: [],
            enums: [],
        }

        node.title = <Dropdown
            trigger={['contextMenu']}
            menu={{
                items: attributeItems,
                onClick: ({ key }) => handleNodeAction(key, node)
            }}>
            <div style={{ cursor: 'pointer' }} onClick={(e) => e.preventDefault()}>
                <Space>
                    <div className="field-row">
                        <FaSquare size={18} />
                        <Input defaultValue={node.target} className="input-item"
                            onChange={(e) => {
                                node.target = e.target.value;
                                if (!edited) setEdited(true);
                            }} />
                        <Input defaultValue={node.source} className="select-item"
                            onChange={(e) => {
                                node.source = e.target.value;
                                if (!edited) setEdited(true);
                            }} />
                    </div>
                </Space>
            </div>
        </Dropdown>;

        return node;
    }

    /**
     * Adds a root node.
     * Called when the button at the top left for adding a root node is clicked.
     */
    const addRootNode = () => {
        const nodeModel = createNode();
        const data = [...treeData, nodeModel];
        setTreeData(data);
        if (!edited) setEdited(true);
    }

    /**
     * Adds an root array node.
     * Called when the button at the top left for adding a root node is clicked.
     */
    const addRootArrayNode = () => {
        const nodeModel = createArrayNode();
        const data = [...treeData, nodeModel];
        setTreeData(data);
        if (!edited) setEdited(true);
    }

    /**
     * Adds a root text node.
     * Called when the button at the top left for adding a root text node is clicked.
     */
    const addRootMappedTextNode = () => {
        const nodeModel = createMappedTextNode();
        const data = [...treeData, nodeModel];
        setTreeData(data);
        if (!edited) setEdited(true);
    }

    /**
     * Adds a root text node.
     * Called when the button at the top left for adding a root text node is clicked.
     */
    const addRootStaticTextNode = () => {
        const nodeModel = createStaticTextNode();
        const data = [...treeData, nodeModel];
        setTreeData(data);
        if (!edited) setEdited(true);
    }

    /**
     * Saves the data mapping.
     */
    const saveDataMapping = async () => {

        /**
         * Recursively traverses the tree to convert the data into a format the the server
         * understands and can save.
         * 
         * @param nodes 
         * @param parent 
         * @returns 
         */
        const buildDataMapModel = (nodes: TdiNodeModel[], parent?: DataMapModel): DataMapModel[] => {

            const children = nodes.map((item: TdiNodeModel) => {
                let dataMap: DataMapModel = {
                    key: item?.key.toString(),
                    parentKey: item?.parentKey,
                    type: item.type,
                    source: item.source,
                    target: item.target,
                    required: item.required,
                    converterType: item.converterType,
                    script: item.script,
                    enums: item.enums?.map((item: DataMapModel) => {
                        return {source: item.source, target: item.target};
                    })
                }
                dataMap.children = buildDataMapModel(item.children, dataMap);
                return dataMap;
            });
            return children;
        }

        const children = buildDataMapModel(treeData);
        const dataMap = {
            client: client,
            children: children,
        }

        try {
            await saveDataMap(dataMap).unwrap();
            setEdited(false);
            notification.success({
                message: "Data Mapping Saved!",
                description: `The data mapping configuration has been created.`
            });
        } catch (e) {
            console.log(e);
            notification.error({
                message: "Saving Data Mapping Failed!",
                description: `An error occurred while saving the data mapping configuration.`
            });
        }
    }

    const saveConverter = (values: any) => {
        
        if (currentNodeModel) {
            const data = [...treeData];
            let node = findNodeFromTree(currentNodeModel, data);
            node.required = values.required;
            node.converterType = values.converterType;
            node.enums = values.enums?.map((item: any) => {
                return {source: item.source, target: item.target}
            });
            node.script = values.script;
            setTreeData(data);
        }
        if (!edited) setEdited(true);
        setConverterOpen(false);
    }

    return <div className="data-mapping">
        <div className="data-mapping-header">
            <h1>Data Mapping - {client?.name}</h1>
            <span style={{ display: 'flex', gap: '.5rem' }}>
                <Button type="primary" onClick={addRootNode} icon={<FaHashnode />}>
                    Add Node
                </Button>
                <Button type="primary" onClick={addRootArrayNode} icon={<MdOutlineDataArray />}>
                    Add Array Node
                </Button>
                <Button type="primary" onClick={addRootMappedTextNode} icon={<RiSwapBoxFill />}>
                    Add Mapped Text Node
                </Button>
                <Button type="primary" onClick={addRootStaticTextNode} icon={<RxText />}>
                    Add Static Text Node
                </Button>
            </span>
        </div>
        {treeData.length !== 0 && (
            <div className="data-mapping-tree-content">
                <Tree
                    showIcon
                    defaultExpandAll
                    switcherIcon={<AiOutlineDown />}
                    treeData={treeData}
                />
            </div>
        )}
        
        {loading && 
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 32}}>
                <Spin tip="Loading" size={"large"}/>
            </div>
        }

        <div className="data-mapping-control">
            <Button
                type="primary"
                onClick={saveDataMapping}
                disabled={!edited}
            >
                Save Data Mapping
            </Button>
        </div>

        <ConverterModal
            open={isConverterOpen}
            onCancel={() => setConverterOpen(false)}
            node={currentNodeModel}
            onOk={saveConverter}
        />
    </div>

}

export default DataMappingPage;
