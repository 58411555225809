const componentHeaderTitleStyle: React.CSSProperties = {
  color: '#231F20',
  marginBottom: 8,
  WebkitUserSelect: 'none',
  msUserSelect: 'none',
  userSelect: 'none'
};

const componentItemContainerStyle: React.CSSProperties = {
  borderRadius: 8,
  height: 40,
  border: '.5px solid #015B93',
  padding: '0px 10px 0px 0px',
  backgroundColor: 'rgba(1, 91, 147, 0.04)'
};

const componentItemButtonStyle: React.CSSProperties = {
  border: 'unset',
  backgroundColor: 'transparent',
};

const componentItemLabelStyle: React.CSSProperties = {
  fontSize: 14,
  fontWeight: '500',
  color: '#015B93',
};

export {
  componentHeaderTitleStyle,
  componentItemContainerStyle,
  componentItemButtonStyle,
  componentItemLabelStyle
}