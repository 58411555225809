import { COLOR } from "../../config/color";

const addControlStyle: React.CSSProperties = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '2rem'
};

export {
  addControlStyle,
}