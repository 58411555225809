import React from 'react';
import { Flex, Typography, Select } from 'antd';
import { ComponentProps } from '../model';

const { Text } = Typography;

interface Props {
  component: ComponentProps;
  onChangeField: (cP: ComponentProps, field: string, value: any) => void;
}
const PropertyFieldFontWeight: React.FC<Props> = (props): JSX.Element => {

  if (props.component.type === 'Text' ||
    props.component.type === 'Value' ||
    props.component.type === 'Checkbox'
  ) {
    return (
      <Flex
        vertical={true}
      >
        <Text
          type="secondary"
          style={{
            color: '#231F20',
            fontSize: '14px',
            fontWeight: '500',
            WebkitUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none'
          }}
        >
          Font Weight
        </Text>
        <Select
          style={{ fontSize: "14px" }}
          value={props.component.fontWeight}
          onChange={(value: string) => props.onChangeField(props.component, "fontWeight", value)}
          options={[
            { value: 'normal', label: 'normal' },
            { value: 'bold', label: 'bold' },
            { value: '100', label: '100' },
            { value: '200', label: '200' },
            { value: '300', label: '300' },
            { value: '400', label: '400' }, // Normal
            { value: '500', label: '500' },
            { value: '600', label: '600' },
            { value: '700', label: '700' },// Bold
            { value: '800', label: '800' },
            { value: '900', label: '900' },
          ]}
        />
      </Flex>
    )
  }

  return <></>
}

export default PropertyFieldFontWeight;