import React from 'react';
import { Flex, Typography, Select } from 'antd';
import { ComponentProps } from '../model';

const { Text } = Typography;

interface Props {
  component: ComponentProps;
  onChangeField: (cP: ComponentProps, field: string, value: any) => void;
}
const PropertyFieldFontFamily: React.FC<Props> = (props): JSX.Element => {

  if (props.component.type === 'Text' ||
    props.component.type === 'Value' ||
    props.component.type === 'Checkbox'
  ) {
    return (
      <Flex
        vertical={true}
      >
        <Text
          type="secondary"
          style={{
            color: '#231F20',
            fontSize: '14px',
            fontWeight: '500',
            WebkitUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none'
          }}
        >
          Font Family
        </Text>
        <Select
          style={{ fontSize: "14px", width: '100%' }}
          value={props.component.fontFamily}
          onChange={(value: string) => props.onChangeField(props.component, "fontFamily", value)}
          options={[
            { value: "Georgia", label: "Georgia" },
            { value: "Times New Roman", label: "Times New Roman" },
            { value: "Arial", label: "Arial" },
            { value: "Helvetica", label: "Helvetica" },
            { value: "Arial Black", label: "Arial Black" },
            { value: "Impact", label: "Impact" },
            { value: "Lucida Sans Unicode", label: "Lucida Sans Unicode" },
            { value: "Tahoma", label: "Tahoma" },
            { value: "Verdana", label: "Verdana" },
            { value: "Courier New", label: "Courier New" },
            { value: "Lucida Console", label: "Lucida Console" },
            { value: "serif", label: "serif" },
            { value: "sans-serif", label: "sans-serif" },
            { value: "cursive", label: "cursive" },
            { value: "fantasy", label: "fantasy" },
            { value: "monospace", label: "monospace" },
            { value: "Calibri, sans-serif", label: "Calibri, sans-serif" },
            { value: "'Trebuchet MS', sans-serif", label: "'Trebuchet MS', sans-serif" },
          ]}
          suffixIcon={<Text style={{ color: '#4C4C4C' }}>px</Text>}
        />
      </Flex>
    )
  }

  return <></>
}

export default PropertyFieldFontFamily;