import React from 'react';
import { Flex, Typography, Select } from 'antd';
import { ComponentProps } from '../model';

const { Text } = Typography;

interface Props {
  component: ComponentProps;
  dataMapFields: Array<{ value: any, label: any }>;
  onChangeField: (cP: ComponentProps, field: string, value: any) => void;
}
const PropertyFieldValue: React.FC<Props> = (props): JSX.Element => {

  if (props.component.type === 'Value') {
    return (
      <Flex
        vertical={true}
      >
        <Text
          type="secondary"
          style={{
            color: '#231F20',
            fontSize: '14px',
            fontWeight: '500',
            WebkitUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none'
          }}
        >
          Value
        </Text>
        <Select
          style={{ fontSize: "14px" }}
          value={props.component.value}
          onChange={(value: string) => props.onChangeField(props.component, "value", value)}
          options={props.dataMapFields}
        />
      </Flex>
    )
  }

  return <></>
}

export default PropertyFieldValue;