import React from 'react';
import { Flex, Typography, Select } from 'antd';
import { ComponentProps } from '../model';

const { Text } = Typography;

interface Props {
  component: ComponentProps;
  onChangeField: (cP: ComponentProps, field: string, value: any) => void;
}
const PropertyFieldDistribution: React.FC<Props> = (props): JSX.Element => {

  if (props.component.type === 'Column') {
    return (
      <Flex
        vertical={true}
      >
        <Text
          type="secondary"
          style={{
            color: '#231F20',
            fontSize: '14px',
            fontWeight: '500',
            WebkitUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none'
          }}
        >
          Distribution
        </Text>
        <Select
          style={{ fontSize: "14px" }}
          value={props.component.distributon}
          onChange={(value: string) => props.onChangeField(props.component, "distributon", value)}
          options={[
            { value: 'Automatic', label: 'Automatic' },
            { value: 'Manual', label: 'Manual' },
          ]}
        />
      </Flex>
    )
  }

  return <></>
}

export default PropertyFieldDistribution;