import { apiForPrivate } from '.';
import { USER_ENDPOINT } from '../../config/ap';

const apiUsers = apiForPrivate.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    getUsers: build.query({
      query: params => ({
        url: `${USER_ENDPOINT.USERS}/paged`,
        params
      })
    }),
    addUser: build.mutation({
      query: payload => ({
        url: USER_ENDPOINT.USERS,
        method: 'POST',
        body: payload,
      }),
    }),
    updateUser: build.mutation({
      query: payload => ({
        url: USER_ENDPOINT.USERS,
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteUser: build.mutation({
      query: payload => ({
        url: `${USER_ENDPOINT.USERS}/${payload.id}`,
        method: 'DELETE',
      }),
    }),
  })
});

export const {
  useLazyGetUsersQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = apiUsers;