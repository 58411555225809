import React from 'react';
import { Flex, Typography, Input, Divider, Radio, Button, Select, Space } from 'antd';
import { ComponentProps } from './model';
import { FaPalette, FaRegTrashCan } from "react-icons/fa6";
import { FaPlusCircle } from "react-icons/fa";
import { componentPropsHeaderButtonDeleteStyle, componentPropsHeaderButtonDeleteTextStyle, componentPropsHeaderButtonDuplicateStyle, componentPropsHeaderButtonDuplicateTextStyle, propertySubTitleStyle, propertyTitleStyle } from './properties-style';

const { Text } = Typography;

interface Props {
  component: ComponentProps;
  addItemComponent: (component: ComponentProps, type: string) => void;
  removeItemComponent: (component: ComponentProps) => void;
  onChangeField: (cP: ComponentProps, field: string, value: any) => void;
}
const ItemSelection: React.FC<Props> = (props): JSX.Element => {

  if (props.component.type === "Field" || 
    props.component.type === "THeader" ||
    props.component.type === "TCell" 
  ) {
    return (
      <Flex
        vertical={true}
      >
        <Text
          type="secondary"
          style={{
            color: '#231F20',
            fontSize: '14px',
            fontWeight: '500',
            WebkitUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none'
          }}
        >
          Items
        </Text>
        <Flex
          style={{ marginTop: 8 }}
          vertical={true}
        >
          <Space
            direction="vertical"
            size="small"
          >
            {props.component.children.map((child: ComponentProps) => (
              <Flex
                key={`child-component-${child.key}`}
                vertical={false}
                gap={8}
              >
                <Select
                  style={{ fontSize: "16px", width: "100%" }}
                  value={child.type}
                  onChange={(value: string) => props.onChangeField(child, "type", value)}
                  options={[
                    { value: 'Text', label: 'Text' },
                    { value: 'Value', label: 'Value' },
                    { value: 'Checkbox', label: 'Checkbox' },
                  ]}
                />
                {!props.component.isParent && (
                  <Flex
                    align='center'
                  >
                    <FaRegTrashCan
                      style={{ cursor: 'pointer' }}
                      onClick={() => props.removeItemComponent(props.component)}
                      size={24}
                      color='red'
                    />

                  </Flex>
                )}

              </Flex>
            ))}
          </Space>

        </Flex>

        <Flex
          align='center'
          gap={4}
          style={{ marginTop: 8, cursor: 'pointer' }}
          onClick={() => props.addItemComponent(props.component, "Text")}
        >

          <FaPlusCircle size={13} color='#015B93' />
          <Text
            type="secondary"
            copyable={false}
            style={{
              color: ' #015B93',
              fontSize: 14,
              fontWeight: 700,
              WebkitUserSelect: 'none',
              msUserSelect: 'none',
              userSelect: 'none'
            }}
          >
            Add Item
          </Text>
        </Flex>
      </Flex>
    )
  }

  if (props.component.type === "Column") {
    return (
      <Flex
        vertical={true}
      >
        <Text
          type="secondary"
          style={{
            color: '#231F20',
            fontSize: '14px',
            fontWeight: '500',
            WebkitUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none'
          }}
        >
          Items
        </Text>
        <Flex
          style={{ marginTop: 8 }}
          vertical={true}
        >
          <Space
            direction="vertical"
            size="small"
          >
            {props.component.children.map((child: ComponentProps) => (
              <Flex
                key={`child-component-${child.key}`}
                vertical={false}
                gap={8}
              >
                <Select
                  style={{ fontSize: "16px", width: "100%" }}
                  value={child.type}
                  onChange={(value: string) => props.onChangeField(child, "type", value)}
                  options={[
                    { value: 'Field', label: 'Field' },
                  ]}
                />
                {!props.component.isParent && (
                  <Flex
                    align='center'
                  >
                    <FaRegTrashCan
                      style={{ cursor: 'pointer' }}
                      onClick={() => props.removeItemComponent(props.component)}
                      size={24}
                      color='red'
                    />

                  </Flex>
                )}

              </Flex>
            ))}
          </Space>

        </Flex>

        <Flex
          align='center'
          gap={4}
          style={{ marginTop: 8, cursor: 'pointer' }}
          onClick={() => props.addItemComponent(props.component, "Field")}
        >

          <FaPlusCircle size={13} color='#015B93' />
          <Text
            type="secondary"
            copyable={false}
            style={{
              color: ' #015B93',
              fontSize: 14,
              fontWeight: 700,
              WebkitUserSelect: 'none',
              msUserSelect: 'none',
              userSelect: 'none'
            }}
          >
            Add Item
          </Text>
        </Flex>
      </Flex>
    )
  }

  if (props.component.type === "Table") {
    return (
      <Flex
        vertical={true}
      >
        <Text
          type="secondary"
          style={{
            color: '#231F20',
            fontSize: '14px',
            fontWeight: '500',
            WebkitUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none'
          }}
        >
          Items
        </Text>
        <Flex
          style={{ marginTop: 8 }}
          vertical={true}
        >
          <Space
            direction="vertical"
            size="small"
          >
            {props.component.children.map((child: ComponentProps) => (
              <Flex
                key={`child-component-${child.key}`}
                vertical={false}
                gap={8}
              >
                <Select
                  style={{ fontSize: "16px", width: "100%" }}
                  value={child.type}
                  onChange={(value: string) => props.onChangeField(child, "type", value)}
                  options={[
                    { value: 'THeader', label: 'Table Header' },
                    { value: 'TCell', label: 'Table Cell' },
                  ]}
                />
                {!props.component.isParent && (
                  <Flex
                    align='center'
                  >
                    <FaRegTrashCan
                      style={{ cursor: 'pointer' }}
                      onClick={() => props.removeItemComponent(props.component)}
                      size={24}
                      color='red'
                    />

                  </Flex>
                )}

              </Flex>
            ))}
          </Space>

        </Flex>

        <Flex
          align='center'
          gap={4}
          style={{ marginTop: 8, cursor: 'pointer' }}
          onClick={() => props.addItemComponent(props.component, "THeader")}
        >

          <FaPlusCircle size={13} color='#015B93' />
          <Text
            type="secondary"
            copyable={false}
            style={{
              color: ' #015B93',
              fontSize: 14,
              fontWeight: 700,
              WebkitUserSelect: 'none',
              msUserSelect: 'none',
              userSelect: 'none'
            }}
          >
            Add Item
          </Text>
        </Flex>
      </Flex>
    )
  }

  return <></>
}

export default ItemSelection;